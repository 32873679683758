
import React ,{useState,useEffect} from 'react'
import { useAuth } from '../contexts/authContext'; 
import {useApi} from '../config'
import SideBar from '../components/SideBar';
import Select from 'react-select'
import { Switch } from '@mui/material';
import NavBar from '../components/NavBar';
import { useNavigate } from 'react-router-dom';
export default function CreateMilieu(){
    const [name,setName] = useState("")
    const [priv, setPriv] = useState(false)
    const { user, isAuthenticated, isLoading }  = useAuth();
    const [categories,setCategories] = useState([])
    const [selectCats,setSelectCats] = useState([])
    const [autojoin,setAutojoin] = useState(false)
    const navigate = useNavigate()

    useEffect(()=>{
        api.api.get(`/api/getAllCategories`)
        .then((res)=>setCategories(res.data.map(i=>{return{label: i.name,value:i.id}})))
    },[])
    useEffect(()=>{
        if(isAuthenticated){
            api.api.post('/api/signup',{email:user.email})
        }},[isLoading])

    const handleChange = () => {
        setPriv(!priv)
    }
    var api = useApi()
    const handleSubmit=(e)=>{
        e.preventDefault();
        api.api.post('/api/createMilieu',{name:name,email:user.email,pub:priv,categories:selectCats.map(i=>i.value)})
        navigate("/home")
    }
    if (isLoading) {
        return <div>Loading ...</div>;
    }
    return( 
        <div>
        <NavBar/>
        <SideBar />
        <div>
            <h1>Create a Milieu</h1>
            <form onSubmit={handleSubmit}>
            <input placeholder='Give your milieu a name!' value={name} onChange={(v)=>{setName(v.target.value)}}>
            
            </input>
            <div>
                <label>Public</label>
                <Switch onChange={handleChange} value={priv} />
            </div>

            <div>
                <label>Categories</label>
                <Select options={categories} isMulti={true} value={selectCats} onChange={setSelectCats} />
            </div>
            <div>
            <button>Create</button>
            </div>
            </form>
        </div>
        </div>
    )
}