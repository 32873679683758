import React, { useState, useEffect } from "react"
import { useApi } from '../config'
import NavBar from "../components/NavBar"
import { useNavigate } from "react-router-dom"
import SideBar from "../components/SideBar"
export default function Search() {
    var nav = useNavigate();
    var api = useApi()
    const [search, setSearch] = useState("")
    const [results, setResults] = useState([])
    const [cats, setCats] = useState([])
    useEffect(() => {
        if(search){
        api.api.get(`/api/findAllMilieus/?search=${search}`)
            .then((res) => {
                setResults(res.data)
            })

            .catch((err) => {

            })
        api.api.get(`/api/getMilieusBySearchedCategory/?search=${search}`)
            .then((res) => setCats(res.data))
        }
    }, [search])


    return (
        <>
            <NavBar />
            <SideBar />
            <h1>
                Search
            </h1>

            <input value={search} onChange={(v) => { setSearch(v.target.value) }} placeholder="Search for a milieu!"></input>
            <div>
                <h2>By Name</h2>
                {

                    results.map(i =>
                        <h3 onClick={() => { nav(`/milieu/${i.name}/${i.id}`) }}>{i.name}</h3>
                    )

                }
            </div>
            <div>
                <h2>By Category</h2>
                {

                    cats.map(i => `${i.cname}-${i.cid}`).filter((v, i, a) => a.indexOf(v) == i).map(

                        i => <div>
                            <div>
                                <h3>{i.split('-')[0]}</h3>
                            </div>
                            {cats.filter(j => `${j.cid}` == `${i.split('-')[1]}`).map(j => 

                                <div onClick={() => { nav(`/milieu/${j.mname}/${j.mid}`) }}>
                                    {j.mname}
                                </div>

                            )}
                        </div>
                    )

                }
            </div>


        </>
    )
}