
import React from 'react'
import { useAuth } from '../contexts/authContext';
import { doSignOut } from '../firebase/auth';

import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import "../css/NavBar.css"
export default function NavBar() {
    const navigate = useNavigate()
    const {userLoggedIn} = useAuth()
    return (

        <>
            <nav className="nav">
                <ul>
                    <li><Link to="/search">Search</Link></li>
                    <li><Link to="/home">Home</Link></li>
                    <li><Link to="/settings">Settings</Link></li>

                    <li  onClick={() => {doSignOut().then(()=>{navigate('/')})  }}>Log Out</li>
                </ul>
            </nav>


        </>
    )
}