import Cal from '../components/Cal.js'
import NavBar from '../components/NavBar.js'
import axios from 'axios';
import React,{useEffect, useState} from 'react';
import { CgCommunity } from "react-icons/cg";
import { HiMiniUserGroup } from "react-icons/hi2";
import { MdGroupAdd } from "react-icons/md";
import { FaUserFriends } from "react-icons/fa";
import { BiSolidMessageAltDetail } from "react-icons/bi";
import { IoCalendarNumber } from "react-icons/io5";
import { IoIosCreate } from "react-icons/io";
import { TbCalendarPlus } from "react-icons/tb";
import "../css/home.css"
import { useApi } from '../config.js'
import { useAuth } from '../contexts/authContext/index.js';
import SideBarItem from '../components/SideBarItem.js';
import SideBar from '../components/SideBar.js'
import { useNavigate } from "react-router-dom";
export default function Contacts(props) {
    const api = useApi()
    const navigate = useNavigate()
    const [toggle,setToggle] = useState(false)
    const { user, isAuthenticated, isLoading }  = useAuth();
    const [requests,setRequests] = useState([])
    const [contacts,setContacts] = useState([])
    useEffect(()=>{
        api.api.get(`/api/getContactsByUser/?email=${user.email}`)
        .then((res)=>setContacts(res.data))
        api.api.get(`/api/getContactRequests/?email=${user.email}`)
        .then((res)=>setRequests(res.data))
    },[])
    useEffect(()=>{
        if(isAuthenticated){
            api.api.post('/api/signup',{email:user.email})
        }},[isLoading])
    if (isLoading) {
        return <div>Loading ...</div>;
    }
    const handleAccept = (email)=>{
        api.api.post(`/api/acceptContactRequest/`,{email:user.email ,subject:email})
        .then((res)=>console.log(res.data))
    }
    const handleIgnore = (email)=>{
        api.api.post(`/api/ignoreContactRequest/`,{email:user.email ,subject:email})
        .then((res)=>console.log(res.data))
    }
    return (
        <div>
             <NavBar/>
            <SideBar />
            <h1>Contacts</h1>
        {contacts.map(i=><div onClick={()=>{navigate(`/user/${i.users_email==user.email?i.users_email1:i.users_email}`)}}>
            {i.users_email==user.email?i.users_email1:i.users_email}
        </div>)}
            <div>
                <h1>Requests</h1>
                {requests.map(i=><div>
                {i.users_email}
                <div>
                <button onClick={()=>{handleAccept(i.users_email)}}>Accept</button>
                <button onClick={()=>{handleIgnore(i.users_email)}}>Ignore</button>
                </div>
                </div>)}
            </div>
        </div>
    )
}