

import React, { useState, useEffect, useRef } from 'react';
import rrulePlugin from '@fullcalendar/rrule'
import '../../css/modal.css'
import 'react-datetime-picker/dist/DateTimePicker.css';
import 'react-calendar/dist/Calendar.css';
import 'react-clock/dist/Clock.css';
import "react-datepicker/dist/react-datepicker.css";
import "react-number-picker/dist/style.css"
import DatePicker from "react-multi-date-picker"
import { DayPicker } from "react-day-picker";
import "react-day-picker/style.css";
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import Card from 'react-bootstrap/Card';
import ListGroup from 'react-bootstrap/ListGroup';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Collapse from 'react-bootstrap/Collapse';
import Stack from 'react-bootstrap/Stack';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Nav from 'react-bootstrap/Nav';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import DateTimePicker from 'react-datetime-picker';
import { StandaloneSearchBox, LoadScript, useJsApiLoader } from '@react-google-maps/api';
import Select from 'react-select'
import { datetime, RRule, RRuleSet, rrulestr } from 'rrule'
import Switch from '@mui/material/Switch';
import moment from 'moment'
import { useApi } from '../../config'
import { MultiSelect } from "react-multi-select-component";
import { useAuth } from '../../contexts/authContext/index.js';
import { TagsInput } from "react-tag-input-component"
import { FaPray } from 'react-icons/fa';
import { List } from '@mui/material';
const stripe = require('stripe')('sk_test_51OLsoxHgQmd0MvYkfDfYbnTXKPezdKht0Y10j7LwgYXd4mFXSgU6ygSxL26VgzY0dAgAuClaFtD0iUXL7n7mxXVN00xWPmeoFI');

const options = [
    { value: 'never', label: 'Never' },
    { value: 0, label: 'Every Day' },
    { value: 1, label: 'Every Week' },
    { value: 2, label: 'Every Month' },
    { value: 3, label: 'Every Year' },
    { value: 'custom', label: 'Custom' }
]

const custOptions = [
    { value: 'daily', label: 'Daily' },
    { value: 'weekly', label: 'Weekly' },
    { value: 'monthly', label: 'Monthly' },
    { value: 'yearly', label: "Yearly" }
]

const dayOptions = [
    { value: 6, label: 'Sunday' },
    { value: 0, label: 'Monday' },
    { value: 1, label: 'Tuesday' },
    { value: 2, label: 'Wednesday' },
    { value: 3, label: 'Thursday' },
    { value: 4, label: 'Friday' },
    { value: 5, label: 'Saturday' },
    { value: 'd', label: 'day' },
    { value: 'w', label: 'weekday' },
    { value: 'we', label: 'weekend day' },

]
const timeModes = [
    { value: 0, label: "minutes" },
    { value: 1, label: "hours" },
    { value: 2, label: "days" },
]
const onthe = [
    { value: 1, label: 'first' },
    { value: 2, label: 'second' },
    { value: 3, label: 'third' },
    { value: 4, label: 'fourth' },
    { value: 5, label: 'fifth' },
    { value: -1, label: 'last' },
]

var date = new Date()
export default function EventSetup(props) {
    const [tags, setTags] = useState([])
    const [selectedTags, setSelectedTags] = useState([])
    const { user } = useAuth();
    var api = useApi();
    const [title, setTitle] = useState("")
    const [allDay, setAllDay] = useState(false)
    const [multiDate, setMultiDate] = useState([new Date()])
    //const [modal, setModal] = useState(false);
    const [value, onChange] = useState(moment().format())
    const [valueEnd, onChangeEnd] = useState(moment().add(1, 'hour').format())
    const [select, setSelect] = useState('never');
    const [cSelect, setCSelect] = useState('daily');
    const [cSelect2, setCSelect2] = useState([]);
    const [every, setEvery] = useState(1)
    const [eachOr, setEachOr] = useState(false);
    const [onThe, setOnThe] = useState(1)
    const [endRepeat, setEndRepeat] = useState(new Date())
    const [monthly, setMonthly] = useState(0)
    const [contacts, setContacts] = useState([])
    const [selectedContacts, setSelectedContacts] = useState([])
    const [selectedUncontacted, setSelectedUncontacted] = useState([])
    const [until, setUntil] = useState(false)
    const [after, setAfter] = useState(0)
    const [forever, setForever] = useState(false)
    const [desc, setDesc] = useState("")
    const [location, setLocation] = useState("")
    const inputRef = useRef()
    const [paid, setPaid] = useState(false)
    const [fixed, setFixed] = useState(false)
    const [google, setGoogle] = useState(window.google)
    const [price, setPrice] = useState(0)
    const [timeBefore, setTimeBefore] = useState(0)
    const [upFront, setUpFront] = useState(true)
    const [totalCost, setTotalCost] = useState(0)
    const [tMode, setTMode] = useState(2)
    const [stripeAcc, setStripeAcc] = useState({})
    const handlePlaceChange = () => {

        const [place] = inputRef.current.getPlaces()
        if (place) {
            console.log(place.formatted_address)
        }
    }
    useEffect(()=>{
        console.log(stripeAcc)
    },[stripeAcc])
    useEffect(() => {
        if (select == "never") {
            setUpFront(false)
        }
    }, [select])
    useEffect(() => {

        settleStripe()
        api.api.get(`/api/getAllContacts/?email=${user.email}`)
            .then((res) => setContacts(res.data.map(i => { return { value: i, label: i } })))
        api.api.get(`/api/getUserTags/?email=${user.email}`)
            .then((res) => setTags(res.data.map(i => { return { label: `${i.prioritization} | ${i.name}`, value: i.tags_id } })))

    }, [])
    const settleStripe = async () => {
        var accounts = await stripe.accounts.list();
        if (accounts.data.filter(i => i.email == user.email).length > 0) {
            setStripeAcc(accounts.data.filter(i => i.email == user.email)[0])
        } else {
            setStripeAcc(null)
        }

    }
    useEffect(() => {
        console.log("STRIPE", stripeAcc)
    }, [stripeAcc])
    const { isLoaded } = useJsApiLoader({
        googleMapsApiKey: "AIzaSyCW7twUq1dHcBuPvZriEWF4wnmZm0Z5RGc"
    })
    function handleSelect(event) {
        console.log(event.target.value)
        setSelect(event.target.value)
    }
    function custHandleSelect(event) {
        setCSelect(event)
        console.log(event)
    }
    function custHandleSelect2(value) {
        //setCSelect2(event)
    }
    function onTheHandleSelect(event) {
        setOnThe(event)
    }
    function monthlyHandleSelect(event) {
        setMonthly(event)
    }
    const changeMulti = (dates) => {
        setMultiDate(dates);
    };
    async function CreateEvent(re) {
        if (endRepeat.day) {
            var untildate = moment(datetime(endRepeat.year, endRepeat.month.index + 1, endRepeat.day + 1)).format("YYYY-MM-DD")
        }
        else {
            var untildate = endRepeat
        }
        var startDate;
        var endDate;
        var startTime;
        var endTime;
        startDate = moment(value).toISOString(false).split("T")[0]
        endDate = moment(valueEnd).toISOString(false).split("T")[0].split("Z")[0]
        startTime = moment(value).toISOString(false).split("T")[1]
        endTime = moment(valueEnd).toISOString(false).split("T")[1].split("Z")[0]

        var rule
        var day;
        var freq;
        var end;


        var duration = moment.duration(moment(value).diff(moment(valueEnd)))
        var dur = '00:00:00.' + String(duration._milliseconds)
        console.log(dur)

        if (select == "never") {
            console.log("TEST")

        } else if (select != 'custom') {



            switch (`${select}`) {
                case "0":
                    freq = RRule.DAILY;
                    break;
                case "1":
                    freq = RRule.WEEKLY
                    break;
                case "2":
                    freq = RRule.MONTHLY
                    break;
                case "3":
                    freq = RRule.YEARLY
                    break;
            }

            //noncustom

            if (forever) {
                rule = new RRule({
                    freq: freq,
                    dtstart: new Date(moment(value)),
                })

            } else {

                if (until) {
                    rule = new RRule({
                        freq: freq,
                        dtstart: new Date(moment(value)),
                        until: new Date(untildate),
                    })

                } if (!until) {
                    rule = new RRule({
                        freq: freq,
                        dtstart: new Date(moment(value)),
                        count: after,
                    })

                }
            }

        } else {



            if (cSelect.value == "daily") {


                if (forever) {
                    rule = new RRule({
                        freq: RRule.DAILY,
                        dtstart: new Date(moment(value)),
                        interval: every,
                    })
                } else {

                    if (until) {
                        rule = new RRule({
                            freq: RRule.DAILY,
                            dtstart: new Date(moment(value)),
                            interval: every,
                            until: new Date(untildate),
                        })
                    } if (!until) {
                        rule = new RRule({
                            freq: RRule.DAILY,
                            dtstart: new Date(moment(value)),
                            interval: every,
                            count: after,
                        })
                    }
                }

            }
            if (cSelect.value == "weekly") {


                if (forever) {
                    rule = new RRule({
                        freq: RRule.WEEKLY,
                        dtstart: new Date(moment(value)),
                        interval: every,
                        byweekday: cSelect2.map(i => i.value),
                    })
                } else {
                    if (until) {

                        rule = new RRule({
                            freq: RRule.WEEKLY,
                            dtstart: new Date(moment(value)),
                            interval: every,
                            byweekday: cSelect2.map(i => i.value),
                            until: new Date(untildate),
                        })

                    }
                    if (!until) {
                        rule = new RRule({
                            freq: RRule.WEEKLY,
                            dtstart: new Date(moment(value)),
                            interval: every,
                            byweekday: cSelect2.map(i => i.value),
                            count: after,
                        })
                    }
                }


            } else if (cSelect.value == "yearly") {



                if (forever) {
                    rule = new RRule({
                        freq: RRule.YEARLY,
                        dtstart: new Date(moment(value)),
                        interval: every,
                    })
                } else {
                    if (until) {
                        rule = new RRule({
                            freq: RRule.YEARLY,
                            dtstart: new Date(moment(value)),
                            interval: every,
                            until: new Date(untildate),
                        })
                    } if (!until) {
                        rule = new RRule({
                            freq: RRule.YEARLY,
                            dtstart: new Date(moment(value)),
                            interval: every,
                            count: after
                        })
                    }
                }




            }
            else if (cSelect.value == 'monthly') {

                if (eachOr) {
                    console.log("MULTI", multiDate)
                    if (forever) {
                        rule = new RRule({
                            freq: RRule.MONTHLY,
                            dtstart: new Date(moment(value)),
                            interval: every,
                            bymonthday: multiDate.map(o => (o.day || o.getDate()))
                        })
                    } else {
                        if (until) {
                            rule = new RRule({
                                freq: RRule.MONTHLY,
                                dtstart: new Date(moment(value)),
                                interval: every,
                                until: new Date(untildate),
                                bymonthday: multiDate.map(o => (o.day || o.getDate()))
                            })
                        } if (!until) {
                            rule = new RRule({
                                freq: RRule.MONTHLY,
                                dtstart: new Date(moment(value)),
                                interval: every,
                                count: after,
                                bymonthday: multiDate.map(o => (o.day || o.getDate()))
                            })
                        }
                    }



                } else {


                    if (monthly.value == 'd') {
                        console.log("ONTHE", 0 + onThe)
                        if (forever) {
                            rule = new RRule({
                                freq: RRule.MONTHLY,
                                dtstart: new Date(moment(value)),
                                interval: every,
                                byweekday: [0, 1, 2, 3, 4, 5, 6],
                                bysetpos: parseInt(`${onThe.value}`),
                            })
                        } else {
                            if (until) {
                                rule = new RRule({
                                    freq: RRule.MONTHLY,
                                    dtstart: new Date(moment(value)),
                                    interval: every,
                                    until: new Date(untildate),
                                    byweekday: [0, 1, 2, 3, 4, 5, 6],
                                    bysetpos: parseInt(`${onThe.value}`),
                                })
                            } if (!until) {
                                rule = new RRule({
                                    freq: RRule.MONTHLY,
                                    dtstart: new Date(moment(value)),
                                    interval: every,
                                    count: after,
                                    byweekday: [0, 1, 2, 3, 4, 5, 6],
                                    bysetpos: parseInt(`${onThe.value}`),
                                })
                            }
                        }




                    } else if (monthly.value == 'w') {

                        if (forever) {
                            rule = new RRule({
                                freq: RRule.MONTHLY,
                                dtstart: new Date(moment(value)),
                                interval: every,
                                byweekday: [0, 1, 2, 3, 4],
                                bysetpos: parseInt(`${onThe.value}`),
                            })
                        } else {
                            if (until) {
                                rule = new RRule({
                                    freq: RRule.MONTHLY,
                                    dtstart: new Date(moment(value)),
                                    interval: every,
                                    until: new Date(untildate),
                                    byweekday: [0, 1, 2, 3, 4],
                                    bysetpos: parseInt(`${onThe.value}`),
                                })
                            } if (!until) {
                                rule = new RRule({
                                    freq: RRule.MONTHLY,
                                    dtstart: new Date(moment(value)),
                                    interval: every,
                                    count: after,
                                    byweekday: [0, 1, 2, 3, 4],
                                    bysetpos: parseInt(`${onThe.value}`),
                                })
                            }
                        }



                    } else if (monthly.value == 'we') {
                        if (forever) {
                            rule = new RRule({
                                freq: RRule.MONTHLY,
                                dtstart: new Date(moment(value)),
                                interval: every,
                                byweekday: [5, 6],
                                bysetpos: parseInt(`${onThe.value}`),
                            })
                        } else {
                            if (until) {
                                rule = new RRule({
                                    freq: RRule.MONTHLY,
                                    dtstart: new Date(moment(value)),
                                    interval: every,
                                    until: new Date(untildate),
                                    byweekday: [5, 6],
                                    bysetpos: parseInt(`${onThe.value}`),
                                })
                            } if (!until) {
                                rule = new RRule({
                                    freq: RRule.MONTHLY,
                                    dtstart: new Date(moment(value)),
                                    interval: every,
                                    count: after,
                                    byweekday: [5, 6],
                                    bysetpos: parseInt(`${onThe.value}`),
                                })
                            }
                        }



                    }
                    else {
                        if (forever) {
                            rule = new RRule({
                                freq: RRule.MONTHLY,
                                dtstart: new Date(moment(value)),
                                interval: every,
                                byweekday: monthly.value,
                                bysetpos: parseInt(`${onThe.value}`)
                            })
                        } else {
                            if (until) {
                                rule = new RRule({
                                    freq: RRule.MONTHLY,
                                    dtstart: new Date(moment(value)),
                                    interval: every,
                                    until: new Date(untildate),
                                    byweekday: monthly.value,
                                    bysetpos: parseInt(`${onThe.value}`)
                                })
                            } if (!until) {
                                rule = new RRule({
                                    freq: RRule.MONTHLY,
                                    dtstart: new Date(moment(value)),
                                    interval: every,
                                    count: after,
                                    byweekday: monthly.value,
                                    bysetpos: parseInt(`${onThe.value}`)
                                })
                            }
                        }



                    }

                }
            }

        }


        props.setModal(!props.modal)

        if (paid) {
            fetch("/.netlify/functions/settleAccounts", {
                method: "POST",
                body: JSON.stringify({
                    email: user.email
                })
            }).then(async res => {
                const result = await res.json()
                if (result.url !== null) {
                    window.open(result.url)
                }

                api.api.post('/api/addAccount', { email: user.email, acc: result.acc })

            })

            var accounts = await stripe.accounts.list();
            if(accounts.data.length>0){
            if (accounts.data.filter(i => i.email == user.email && i.details_submitted).length > 0) {

                var invitees = [...selectedUncontacted, ...selectedContacts.map(i => i.value)]
                if (!props.cal.isGoogle) {
                    if (props.mode == "home") {
                        api.api.post('/api/createUserEvent', { isPaid: paid, splitPaid: !fixed, periodBefore: timeBefore, unitBefore: tMode.value, upFront: upFront, price: fixed ? price : totalCost, description: desc, invitees: invitees, email: user.email, tags: selectedTags.map(i => i.value), id: props.cal.id, rrule: rule ? rule.toString() : null, start: moment.utc(value).toISOString(false), title: title, allday: allDay, duration: dur })
                            .then((res) => {
                                console.log("RESETTING")
                                props.reset()
                            })

                    } else if (props.mode == "milieu") {
                        api.api.post('/api/createMEvent', { isPaid: paid, splitPaid: !fixed, periodBefore: timeBefore, unitBefore: tMode.value, upFront: upFront, price: fixed ? price : totalCost, description: desc, invitees: invitees, tags: selectedTags.map(i => i.value), id: props.id, rrule: rule ? rule.toString() : null, start: moment.utc(value).toISOString(false), title: title, allday: allDay, duration: dur })
                            .then((res) => {
                                props.reset()
                            })
                    }
                }
                else {

                    api.api.post('/api/createGoogleEvent', { description: desc, invitees: invitees, email: user.email, tags: selectedTags, calid: props.cal.id, rrule: rule ? rule.toString() : null, start: moment(value).toISOString(false), title: title, allday: allDay, duration: dur })
                        .then((res) => {
                            props.reset()
                        })

                }

                if (invitees.length > 0) {
                    api.api.post('/api/emailInvite', { description: desc, invitees: invitees, email: user.email, tags: selectedTags, calid: props.cal.id, rrule: rule ? rule.toString() : null, start: moment(value).toISOString(false), title: title, allday: allDay, duration: dur })
                }
            }}




        } else {

            var invitees = [...selectedUncontacted, ...selectedContacts.map(i => i.value)]
            if (!props.cal.isGoogle) {
                if (props.mode == "home") {
                    api.api.post('/api/createUserEvent', { isPaid: paid, splitPaid: !fixed, periodBefore: timeBefore, unitBefore: tMode.value, upFront: upFront, price: fixed ? price : totalCost, description: desc, invitees: invitees, email: user.email, tags: selectedTags.map(i => i.value), id: props.cal.id, rrule: rule ? rule.toString() : null, start: moment.utc(value).toISOString(false), title: title, allday: allDay, duration: dur })
                        .then((res) => {
                            console.log("RESETTING")
                            props.reset()
                        })

                } else if (props.mode == "milieu") {
                    api.api.post('/api/createMEvent', { isPaid: paid, splitPaid: !fixed, periodBefore: timeBefore, unitBefore: tMode.value, upFront: upFront, price: fixed ? price : totalCost, description: desc, invitees: invitees, tags: selectedTags.map(i => i.value), id: props.id, rrule: rule ? rule.toString() : null, start: moment.utc(value).toISOString(false), title: title, allday: allDay, duration: dur })
                        .then((res) => {
                            props.reset()
                        })
                }
            }
            else {

                api.api.post('/api/createGoogleEvent', { description: desc, invitees: invitees, email: user.email, tags: selectedTags, calid: props.cal.id, rrule: rule ? rule.toString() : null, start: moment(value).toISOString(false), title: title, allday: allDay, duration: dur })
                    .then((res) => {
                        props.reset()
                    })

            }

            if (invitees.length > 0) {
                api.api.post('/api/emailInvite', { description: desc, invitees: invitees, email: user.email, tags: selectedTags, calid: props.cal.id, rrule: rule ? rule.toString() : null, start: moment(value).toISOString(false), title: title, allday: allDay, duration: dur })
            }


        }

    }

    useEffect(() => {




        setTitle("")
        setAllDay(false)
        setMultiDate([new Date()])

        onChange(moment().format())
        onChangeEnd(moment().add(1, 'hour').format())
        setSelect('never');
        setCSelect('daily');
        setCSelect2([]);
        setEvery(1)
        setEachOr(false);
        setOnThe(1)
        setEndRepeat(new Date())
        setMonthly(0)
        setContacts([])
        setSelectedContacts([])
        setSelectedUncontacted([])
        setUntil(false)
        setAfter(0)
        setForever(false)
        setDesc("")




    }, [props.modal])
    useEffect(() => {
        if (endRepeat.year) {
            console.log(endRepeat)
            console.log(moment(datetime(endRepeat.year, endRepeat.month.index + 1, endRepeat.day + 1)).format("YYYY-MM-DD"))
            console.log(new Date(moment(endRepeat)))
        }
    }, [endRepeat])
    return (
        <>
            <Modal style={{ padding: "100px" }} show={props.modal} fullscreen={true} onHide={() => { props.setModal(false) }}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        Event Creation
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <div >

                        <div>
                            <Card>
                                <ListGroup>
                                    <ListGroup.Item style={{ margin: "10px" }}>

                                        <Form.Control
                                            placeholder='Title'
                                            value={title}
                                            onChange={(v) => { setTitle(v.target.value) }}
                                        />
                                    </ListGroup.Item>
                                    <ListGroup.Item>
                                        <Form.Control placeholder='Description' rows="4" cols="10" value={desc} onChange={(v) => { setDesc(v.target.value) }} as="textarea" />
                                    </ListGroup.Item>
                                </ListGroup>
                            </Card>
                            <Card>
                                <Card.Body>
                                    <div style={{ display: "flex", justifyContent: "space-around" }}>
                                        <div style={{ marginTop: "6%" }}>All-day <Switch value={allDay} onChange={() => setAllDay(!allDay)} /></div>
                                        <ListGroup style={{ display: "flex" }}>
                                            <ListGroup.Item>Starts <DateTimePicker disableClock={true} onChange={(e) => { onChange(moment(e).format()) }} value={value} /></ListGroup.Item>
                                            <ListGroup.Item>Ends <DateTimePicker disableClock={true} onChange={onChangeEnd} value={valueEnd} /> </ListGroup.Item>
                                        </ListGroup>
                                    </div>
                                </Card.Body>
                            </Card>
                            <Card>
                                <Card.Body>
                                    <ListGroup>
                                        <ListGroup.Item style={{ display: "flex" }}>
                                            <div style={{ flexGrow: "3" }}>
                                                <div>Repeat</div>
                                                <div>
                                                    <Select onChange={(e) => { console.log(e.value); setSelect(e.value) }}
                                                        options={options}
                                                    />
                                                </div>
                                            </div>
                                            <div>
                                                <div>
                                                    <label>Forever</label>
                                                </div>
                                                <div>
                                                    <Switch checked={forever} onClick={() => setForever(!forever)} />
                                                </div>
                                            </div>
                                        </ListGroup.Item>



                                        {select != "never" ? !forever ?
                                            <ListGroup.Item>
                                                <div>
                                                    End Repeat
                                                    <div style={{ display: "flex" }}>
                                                        <div style={{ display: "flex" }}>
                                                            <div style={{ padding: "10px" }}>
                                                                <Form.Check

                                                                    onClick={() => setUntil(!until)}
                                                                    checked={!until}
                                                                />
                                                            </div>
                                                            <div style={{ display: "flex", justifyContent: "space-around", alignItems: "cemter", padding: "10px" }}>
                                                                <div style={{ marginRight: "10px", textAlign: "center" }}>
                                                                    After
                                                                </div>
                                                                <Form.Control
                                                                    style={{ width: `${(`${after}`).length * 50}px`, height: "30px", WebkitAppearance: "none" }}
                                                                    disabled={until}
                                                                    value={after} onChange={(o) => { console.log(o.target.value); setAfter(o.target.value) }}
                                                                    type='number'
                                                                />
                                                                <div style={{ marginLeft: "10px" }}>
                                                                    occurrences
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                    <div style={{ display: "flex" }}>

                                                        <div style={{ display: "flex" }}>
                                                            <div style={{ padding: "10px" }}>
                                                                <Form.Check
                                                                    onClick={() => setUntil(!until)}
                                                                    checked={until}
                                                                />
                                                            </div>
                                                            <div style={{ display: "flex", justifyContent: "space-around", alignItems: "cemter", padding: "10px" }}>
                                                                <div style={{ marginRight: "10px", textAlign: "center" }}>
                                                                    Until
                                                                </div>

                                                                <DatePicker disabled={!until} multiple={false} value={endRepeat} onChange={setEndRepeat} />

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </ListGroup.Item> : null
                                            :
                                            null
                                        }


                                        {select == "custom" ?
                                            <ListGroup>
                                                <div>
                                                    <ListGroup.Item>
                                                        <label>Frequency</label>
                                                        <Select options={custOptions} value={cSelect} onChange={custHandleSelect} />
                                                    </ListGroup.Item>
                                                    {cSelect.value == "weekly" ?
                                                        <ListGroup.Item>
                                                            <MultiSelect
                                                                options={dayOptions}
                                                                value={cSelect2}
                                                                onChange={setCSelect2}
                                                                labelledBy="Select"
                                                            />
                                                        </ListGroup.Item>
                                                        :
                                                        cSelect.value == "monthly" ?

                                                            <ListGroup.Item>


                                                                <div style={{ display: "flex" }}>
                                                                    <div>


                                                                        <div style={{ padding: "10px" }}>
                                                                            <Form.Check
                                                                                onClick={() => setEachOr(!eachOr)}
                                                                                checked={eachOr}
                                                                            />
                                                                        </div>
                                                                        <div style={{ padding: "10px" }}>
                                                                            <Form.Check
                                                                                onClick={() => setEachOr(!eachOr)}
                                                                                checked={!eachOr}
                                                                            />
                                                                        </div>

                                                                    </div>
                                                                    <div>
                                                                        <div >

                                                                            <div>
                                                                                <div>Each...</div>
                                                                                <DatePicker disabled={!eachOr} multiple={true} value={multiDate} onChange={setMultiDate} shouldCloseOnSelect={false} />
                                                                            </div>
                                                                        </div>

                                                                        <div >
                                                                            <label>on the...</label>
                                                                            <div style={{ display: "flex" }}>

                                                                                <div  >




                                                                                    <Select options={onthe} isDisabled={eachOr} onChange={onTheHandleSelect} />


                                                                                </div>
                                                                                <div >

                                                                                    <Select options={dayOptions} isDisabled={eachOr} onChange={monthlyHandleSelect} />

                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div></div>

                                                            </ListGroup.Item> : null
                                                    }
                                                </div>
                                                <ListGroup.Item><label>Repeat every</label> <input value={every} onChange={(o) => { setEvery(o.target.value) }} type='number' />
                                                    <label>
                                                        {cSelect.value == "daily" ? "day/s" : cSelect.value == "weekly" ? "week/s" : cSelect.value == "monthly" ? "month/s" : cSelect.value == "yearly" ? "year/s" : null}
                                                    </label>

                                                </ListGroup.Item>
                                            </ListGroup>
                                            : null}



                                    </ListGroup>
                                </Card.Body>
                            </Card>
                            <Card>
                                <Card.Header>
                                    <div style={{ display: "flex" }}>
                                        <div className="p-2" >
                                            {stripeAcc?stripeAcc.details_submitted ?
                                            <div style={{ display: "flex" }}>
                                                <Form.Check
                                                    type='switch'
                                                    checked={paid}
                                                    onChange={() => setPaid(!paid)}
                                                />
                                                <label className="p-2" style={{ marginLeft: "10px" }}>Paid Entry</label>
                                                </div>
                                                :
                                                <div>
                                                    <div style={{display:"flex"}}>
                                                    <OverlayTrigger
                                                        placement="right"
                                                        delay={{ show: 250, hide: 400 }}
                                                        overlay={<Tooltip  {...props}>

                                                        </Tooltip>}
                                                    >
                                                        <Form.Check
                                                            disabled={true}
                                                            type='switch'
                                                            checked={paid}
                                                            onChange={() => setPaid(!paid)}
                                                        />


                                                    </OverlayTrigger>

                                                    <label className="p-2" style={{ marginLeft: "10px" }}>Paid Entry</label>
                                                    </div>
                                                    <div>
                                                        You must associate this account with Stripe in the user settings.
                                                    </div>
                                                </div>
                                            :null}

                                        </div>
                                        
                                    </div>

                                </Card.Header>

                                <Collapse in={paid} dimension="height">

                                    <Nav justify variant="tabs" defaultActiveKey="/home">
                                        <Nav.Item>
                                            <Nav.Link onClick={() => setFixed(true)} >Fixed</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link onClick={() => setFixed(false)} >Split</Nav.Link>
                                        </Nav.Item>
                                    </Nav>
                                </Collapse>

                                <Card.Body>
                                    <Collapse in={fixed && paid} dimension="height">
                                        <div id="example-collapse-text">
                                            <Card body style={{ width: '400px' }}>
                                                <ListGroup>
                                                    <ListGroup.Item>
                                                        <div >
                                                            <div>
                                                        <Form.Label>
                                                            Entry Fee in dollars
                                                        </Form.Label>

                                                        <Form.Control
                                                            type="number"
                                                            placeholder="$"
                                                            min={.5}
                                                            value={price}
                                                            onChange={(e) => setPrice(e.target.value < .5 ? .5 : e.target.value)}
                                                        />
                                                        </div>
                                                        <div style={{display:'flex'}}>
                                                        <Form.Label>
                                                            Revenue:
                                                        </Form.Label>

                                                        <div style={{marginLeft:"10px"}}>
                                                            {(price-(price*.15)).toFixed(2)}
                                                        </div>
                                                        </div>
                                                        </div>
                                                    </ListGroup.Item>
                                                    <ListGroup.Item>

                                                        <div >
                                                            {select == "never" ?
                                                                <div>
                                                                    <div style={{ display: "flex", padding: "20px" }}>
                                                                        <Form.Check
                                                                            style={{ marginRight: "10px" }}
                                                                            checked={upFront}
                                                                            onClick={() => setUpFront(!upFront)}
                                                                        />
                                                                        <Form.Label>Charges will be made at the instant of attendance confirmation</Form.Label>
                                                                    </div>

                                                                    <div style={{ display: "flex", padding: "20px" }}>
                                                                        <Form.Check
                                                                            style={{ marginRight: "10px" }}
                                                                            checked={!upFront}
                                                                            onClick={() => setUpFront(!upFront)}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                : null
                                                            }
                                                            <Form.Label>Charges will be made at the determined amount of time before the event</Form.Label>

                                                            <div style={{ padding: "20px" }} >


                                                                <div style={{ display: 'flex' }}>
                                                                    <Form.Control
                                                                        disabled={upFront}
                                                                        style={{ width: "100px" }}
                                                                        type="number"
                                                                        placeholder="Enter a period"
                                                                        min={.5}
                                                                        value={timeBefore}
                                                                        onChange={(e) => setTimeBefore(e.target.value < .5 ? .5 : e.target.value)}
                                                                    />
                                                                    <Select
                                                                        isDisabled={upFront}
                                                                        options={timeModes}
                                                                        onChange={setTMode}
                                                                        value={tMode}
                                                                    />
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </ListGroup.Item>
                                                </ListGroup>
                                            </Card>
                                        </div>
                                    </Collapse>
                                    <Collapse in={!fixed && paid} dimension="height">
                                        <div id="example-collapse-text">
                                            <Card body style={{ width: '400px' }}>


                                                <ListGroup>
                                                    <ListGroup.Item>
                                                <Form.Label>
                                                    Total cost in dollars to be split amongst all attendants
                                                </Form.Label>

                                                <Form.Control
                                                    type="number"
                                                    placeholder="$"
                                                    min={.5}
                                                    value={totalCost}
                                                    onChange={(e) => setTotalCost(e.target.value < .5 ? .50 : e.target.value)}
                                                />
                                                <Form.Label>Amount of time preceeding an event, at which point, charges will be made.</Form.Label>
                                                <div style={{ display: 'flex' }}>
                                                    <Form.Control
                                                        style={{ width: "100px" }}
                                                        type="number"
                                                        placeholder="Enter a period"
                                                        min={0}
                                                        value={timeBefore}
                                                        onChange={(e) => setTimeBefore(e.target.value < 0 ? 0 : e.target.value)}
                                                    />
                                                    <Select

                                                        options={timeModes}
                                                        onChange={setTMode}
                                                        value={tMode}
                                                    />

                                                </div>
                                                </ListGroup.Item>
                                                <ListGroup.Item>
                                                            <Form.Label>
                                                                Revenue
                                                            </Form.Label>
                                                            <div>
                                                                {(totalCost-(totalCost*.15)).toFixed(2)}
                                                            </div>
                                                </ListGroup.Item>
                                                </ListGroup>
                                            </Card>
                                        </div>
                                    </Collapse>
                                </Card.Body>
                            </Card>
                        </div>



                        <div>
                            Invite
                            <MultiSelect
                                options={contacts}
                                value={selectedContacts}
                                onChange={setSelectedContacts}
                                labelledBy="Select"
                            />

                            Invite an uncontacted friend
                            <TagsInput
                                value={selectedUncontacted}
                                onChange={setSelectedUncontacted}
                                name="Emails"
                                placeHolder="Enter an email"
                                separators={" "}
                            />
                        </div>
                        {select == "never" ?
                            <Button variant="primary" onClick={() => { CreateEvent(false) }}>
                                Add
                            </Button>
                            :
                            <Button variant="primary" onClick={() => { CreateEvent(true) }}>
                                Add
                            </Button>
                        }

                    </div>
                </Modal.Body>

            </Modal>

        </>
    )
}