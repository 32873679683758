import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import rrulePlugin from '@fullcalendar/rrule'
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import ListGroup from 'react-bootstrap/ListGroup'
import moment from 'moment'
import '../css/modal.css'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import EventEdit from './Modals/EventEdit.js'
import EventSetup from './Modals/EventSetup'
import React, { useState, useEffect } from 'react';
import { toMoment } from '@fullcalendar/moment-timezone'
import SideBar from './SideBar.js'
import { useApi } from '../config'
import CalendarTab from './CalendarTab.js'
import momentTimezonePlugin from '@fullcalendar/moment-timezone';
import { useAuth } from '../contexts/authContext/index.js';
import Event from './Modals/Event'
export default function Cal(props) {
    const [toggle, setToggle] = useState(false);
    var api = useApi();
    const [mode, setMode] = useState("series");
    const [event, setEvent] = useState({})
    const [edit, setEdit] = useState(false);
    const [modal, setModal] = useState(false);
    var { user } = useAuth()
    const [tags, setTags] = useState([])
    const [particular, setParticular] = useState({})
    const [events, setEvents] = useState([]);
    const [clientEvents, setClientEvents] = useState([])
    const [cal, setCal] = useState({})
    const [resetTab, setResetTab] = useState(0)
    const [eventData, setEventData] = useState([]);
    const [memberStatus, setMemberStatus] = useState({});
    const [googleCalPub, setGoogleCalPub] = useState(true)
    const [calPub, setCalPub] = useState({})
    const [milieuCals, setMilieuCals] = useState([])
    const [googleCals, setGoogleCals] = useState([])
    const [mCals, setMCals] = useState([])
    const [hCals, setHCals] = useState([])
    const [userGoogleEvents, setUserGoogleEvents] = useState([])
    const [userGoogleCals, setUserGoogleCals] = useState([])
    const [userTags, setUserTags] = useState([])
    const [checker, setChecker] = useState(0)
    const [displayedSubEvents, setDisplayedSubEvents] = useState([])
    const [googleSubs, setGoogleSubs] = useState([])
    const [googleSubEvents, setGoogleSubEvents] = useState([])
    const [userSubs, setUserSubs] = useState([])
    const [userGoogleSubs, setUserGoogleSubs] = useState([])
    const [displayedUserTags, setDisplayedUserTags] = useState([])
    const [googleAttendance, setGoogleAttendance] = useState([])
    const [googleAttEvents, setGoogleAttEvents] = useState([])
    const [check, setCheck] = useState(0)
    const [displayedCals, setDisplayedCals] = useState([])
    const [displayedSubCals, setDisplayedSubCals] = useState([])
    const [displayedMilieuCals, setDisplayedMilieuCals] = useState([])
    useEffect(() => {
        if (props.mode == 'home') {

            api.api.get(`/api/getAllCalendarsByUser/?email=${user.email}`)
                .then((res) => {

                    if (res.data.home.filter(i => i.def == 1).length < 1) {
                        setCal({ name: res.data.home.filter(i => i.def == 0)[0].name, isHome: true, id: res.data.home.filter(i => i.def == 0)[0].id })
                    } else {

                        setCal({ name: res.data.home.filter(i => i.def == 1)[0].name, isHome: true, id: res.data.home.filter(i => i.def == 1)[0].id })
                    }

                })
        }
        else if (props.mode == 'milieu') {

            api.api.get(`/api/getAllApparentCalendarsByMilieuId/?id=${props.id}&email=${user.email}`)
                .then((res) => {
                    if (res.data.length > 0) {
                        setMilieuCals(res.data.map(i => { return { name: i.cname, isHome: false, id: i.cid, displayed: true } }))

                    } else {
                        setMilieuCals([{ name: "No Calendars Available", isHome: false, id: 0 }])
                    }
                }

                )

        }
        else if (props.mode == "user") {
            api.api.get(`/api/getAllApparentCalendarsByUser/?email=${user.email}&user=${props.id}`)
                .then((res) => {
                    if (res.data.length > 0) {
                        if (res.data.home.filter(i => i.def == 1).length < 1) {
                            setCal({ name: res.data.home.filter(i => i.def == 0)[0].name, isHome: true, id: res.data.home.filter(i => i.def == 0)[0].id })
                        } else {
                            setCal({ name: res.data.home.filter(i => i.def == 1)[0].name, isHome: true, id: res.data.home.filter(i => i.def == 1)[0].id })
                        }
                    } else {

                        setCal({ name: "No Calendars Available", isHome: true, id: 0 })
                    }

                })

        }
        reset(1)
    }, [])

    useEffect(() => {
        reset(2)
    }, [milieuCals, mCals])
    const addGoogleCals = () => {
        setGoogleCals([])
    }

    useEffect(() => {
        setGoogleSubEvents([])
        for (var i in userGoogleSubs) {
            console.log("THISIS", userGoogleSubs[i])
            api.api.get(`/api/getGidFromCalId?id=${userGoogleSubs[i]}`)
                .then((res) =>
                    api.api.get(`/api/getGoogleEvents/?email=${user.email}&id=${res.data}`)
                        .then((resp) => {
                            console.log([...googleSubEvents, ...resp.data].filter(i => i))
                            setGoogleSubEvents(googleSubEvents => [...googleSubEvents, ...resp.data].filter(i => i))
                        }

                        )
                )
        }
        for (var i in googleSubs) {
            api.api.get(`/api/getGidFromCalId?id=${googleSubs[i]}`)
                .then((res) =>
                    api.api.get(`/api/getGoogleEvents/?email=${user.email}&id=${res.data}`)
                        .then((resp) => {
                            //console.log([...googleSubEvents,...resp.data].filter(i=>i))
                            setGoogleSubEvents(googleSubEvents => [...googleSubEvents, ...resp.data].filter(i => i))
                        }

                        )
                )
        }
    }, [googleSubs, userGoogleSubs])


    useEffect(() => {
        console.log(googleSubEvents)
        reset(3)
    }, [googleSubEvents])
    useEffect(() => {
        if (googleCals.length == 0) {


            if (props.mode == "home") {
                api.api.get(`/api/getDisplayedGoogleCals/?email=${user.email}`)
                    .then(async (res) => {
                        for (var i = 0; i < res.data.length; i++) {
                            await api.api.get(`/api/getGoogleEvents/?email=${user.email}&id=${res.data[i].gid}`)
                                .then((resp) => {
                                    if (resp.data) {
                                        if (resp.data.length > 0) {

                                            setGoogleCals(googleCals => [...googleCals, ...resp.data])

                                        }
                                    }
                                })
                        }

                    })
            }



        }
        reset(4)

    }, [googleCals])

    useEffect(() => {
        if (userGoogleEvents.length == 0) {
            if (props.mode == "user") {

                for (var i = 0; i < userGoogleCals.filter(j => j.displayed).length; i++) {

                    api.api.get(`/api/getGoogleEvents/?email=${user.email}&id=${userGoogleCals.filter(j => j.displayed)[i].gid}`)
                        .then((resp) => {

                            console.log("userGoogleEvents", resp.data)
                            if (resp.data) {
                                setUserGoogleEvents(userGoogleEvents => [...userGoogleEvents, ...resp.data])

                            }
                        })
                }


            }



        }

        reset(5)
    }, [userGoogleEvents])

    useEffect(() => {
        reset(6)
    }, [cal])

    //    useEffect(()=>{
    //    if(googleAttendance[0]=="start"){
    //        api.api.get(`/api/getGoogleAttendance/?email=${user.email}`)
    //        .then(res=>{setGoogleAttendance(res.data)
    //            setCheck(1)
    //            
    //        })
    //    }else{
    //        setCheck(1)
    //        setEvents([])
    //    }
    //     
    //    },[googleAttendance])



    function reset(source = 0) {
        setGoogleCalPub(true)

        if (props.mode == "home") {
            setCheck(1)
            setGoogleAttendance(["start"])

        } else if (props.mode == "milieu") {

            setClientEvents(new Array("start"))


        } else if (props.mode == "user") {
            setClientEvents(new Array("start"))
        }

    }
    useEffect(() => {
        console.log("DISPLAYED", hCals)
        reset(7)

    }, [displayedUserTags, userSubs, mCals, hCals])

    useEffect(() => {
        if (props.mode == "home" && check == 1) {

            api.api.get(`/api/getDisplayedCals?email=${user.email}`)
                .then(res => setDisplayedCals(res.data))
            api.api.get(`/api/getDisplayedSubCals?email=${user.email}`)
                .then(res => setDisplayedSubCals(res.data))
            api.api.get(`/api/getDisplayedMilieuCal?email=${user.email}`)
                .then(res => setDisplayedMilieuCals(res.data))
            api.api.get(`/api/getUserTags/?email=${user.email}`)
                .then((res) => setTags(res.data.map(i => { return { label: `${i.prioritization} | ${i.name}`, value: i.tags_id } })))

            if (cal.isGoogle) {

                api.api.get(`/api/checkGooglePublicity/?calid=${cal.id}&id=${user.email}&self=${true}`)
                    .then((res) => { setGoogleCalPub(res.data) })
            }


            api.api.get(`/api/getCalendarById/?id=${cal.id}&isHome=${cal.isHome}&sub=${cal.sub}&email=${user.email}&isGoogle=${cal.google}`)
                .then((res) => {
                    setCalPub({ contact: res.data.contacted, circle: res.data.circled, public: res.data.public })
                })
            api.api.get(`/api/getAllSyncedEvents/?email=${user.email}`)
                .then((res) => {
                    //google//var finalEvents =  res.data.concat(googleAttendance).filter(k=>k.isSub?k.tags.map(l=>l.tags_id).some(l=>displayedSubEvents?displayedSubEvents.map(j=>j.tags_id).includes(l):k.tags.length>0?false:true):true).concat(googleCals).concat(googleSubEvents.filter(k=>k.tags?k.tags.map(p=>p.value).some(l=>displayedSubEvents.map(j=>j.tags_id).includes(l)):true))
                    //tags //var finalEvents =  res.data.filter(k=>k.isSub?k.tags.map(l=>l.tags_id).some(l=>displayedSubEvents?displayedSubEvents.map(j=>j.tags_id).includes(l):k.tags.length>0?false:true):true)
                    var finalEvents = res.data.filter((v, i, a) => a.map(k => k.id).indexOf(v.id) == i)
                    console.log("FINAL", finalEvents)
                    if (finalEvents.length > 1) {
                        setEvents(finalEvents.map(i => {
                            
                            return {
                                extendedProps: {isPaid:i.isPaid,splitPaid:i.splitPaid,price:i.price,upFront:i.upFront,periodBefore:i.periodBefore,unitBefore:i.unitBefore, type: i.type, description: i.description, attendees: i.attendees, isGoogle: i.isGoogle ? true : false, cname: i.cname, calid: i.calid, mname: i.mname, mcal: i.mid, tags: i.tags, id: i.id, rrule: i.rrule, start: i.start, title: i.title, allday: i.allday, duration: i.duration, exdate: i.exclusions ? i.exclusions.split(',') : null }
                                , rrule: i.rrule, start: i.start, title: i.title, allDay: i.allday, duration: i.allday?null:{ milliseconds: i.duration.split('-')[1] }, exdate: i.exclusions ? i.exclusions.split(',') : null
                            }
                        }).filter((v, k, a) => a.map(l => `${l.extendedProps.id}-${l.extendedProps.calid}`).indexOf(`${v.extendedProps.id}-${v.extendedProps.calid}`) == k))


                    } else if (finalEvents.length == 1) {
                        setEvents([
                            {
                                extendedProps: {isPaid:finalEvents[0].isPaid,splitPaid:finalEvents[0].splitPaid,price:finalEvents[0].price,upFront:finalEvents[0].upFront,periodBefore:finalEvents[0].periodBefore,unitBefore:finalEvents[0].unitBefore, type: finalEvents[0].type, description: finalEvents[0].description, attendees: finalEvents[0].attendees, isGoogle: finalEvents[0].isGoogle, cname: finalEvents[0].cname, calid: finalEvents[0].calid, mname: finalEvents[0].mname, mcal: finalEvents[0].mid, tags: finalEvents[0].tags, id: finalEvents[0].id, rrule: finalEvents[0].rrule, start: finalEvents[0].start, title: finalEvents[0].title, allday: finalEvents[0].allday, duration: finalEvents[0].duration, exdate: finalEvents[0].exclusions ? finalEvents[0].exclusions.split(',') : null },
                                rrule: finalEvents[0].rrule, start: finalEvents[0].start, title: finalEvents[0].title, allDay: finalEvents[0].allday, duration:  finalEvents[0].allday?null: { milliseconds: finalEvents[0].duration.split('-')[1] }, exdate: finalEvents[0].exclusions ? finalEvents[0].exclusions.split(',') : null
                            }
                        ].filter((v, k, a) => a.map(l => `${l.extendedProps.id}-${l.extendedProps.calid}`).indexOf(`${v.extendedProps.id}-${v.extendedProps.calid}`) == k))

                    }
                    else if (res.data.length == 0) {
                        setEvents([{}])
                    }
                })



            setCheck(0)
        }
        if (props.mode == "user" && clientEvents[0] == "start") {
            //if(userSubs.length>0){
            //    for (var k in userSubs.filter(i => i.displayed)) {
            //    api.api.get(`/api/getEventsByCalendarId/?id=${userSubs.filter(i => i.displayed)[k].cid}&isHome=true&email=${props.id}&isSub=true`)
            //        .then((res)=> {
            //            
            //            if (res.data.length > 1) {
            //
            //                setClientEvents(clientEvents => [...clientEvents, ...res.data.map(i => {
            //                    console.log(res.data)
            //                    return {
            //                        extendedProps: { cname: i.cname, calid: i.calid, isGoogle: false, mId: i.mId, status: i.tags ? i.tags.length > 0 ? displayedUserTags.map(k=>k.tags_id).some(l=>i.tags.map(j => j.tag_id).includes(l)) : true : true, tags: i.tags, id: i.id, allDay: i.allday, title: i.title, start: i.start, rrule: i.rrule, duration: i.duration, },
            //                        allDay: i.allday, title: i.title, start: i.start, rrule: i.rrule, duration: i.duration, exdate: i.exclusions ? i.exclusions.split(',') : null
            //                    }
            //                })].filter(k => k !== "start").filter((v, i, a) => a.map(k => k.extendedProps.id).indexOf(v.extendedProps.id) == i))
            //            } else if (res.data.length == 1) {
            //                setClientEvents(clientEvents => [...clientEvents,
            //                {
            //                    extendedProps: { cname: res.data.cname, calid: res.data.calid, isGoogle: true, status: res.data.tags ? res.data.tags.length > 0 ? displayedUserTags.map(k=>k.tags_id).some(l=>res.data.tags.map(j => j.tag_id).includes(l)) : true : true, mId: res.data.concat(googleCals)[0].mId, mname: res.data.concat(googleCals)[0].mname, mcal: res.data.concat(googleCals)[0].mid, tags: res.data.concat(googleCals)[0].tags, id: res.data.concat(googleCals)[0].id, rrule: res.data.concat(googleCals)[0].rrule, start: res.data.concat(googleCals)[0].start, title: res.data.concat(googleCals)[0].title, allday: res.data.concat(googleCals)[0].allday, duration: res.data.concat(googleCals)[0].duration, exdate: res.data.concat(googleCals)[0].exclusions ? res.data.concat(googleCals)[0].exclusions.split(',') : null },
            //                    rrule: res.data.concat(googleCals)[0].rrule, start: res.data.concat(googleCals)[0].start, title: res.data.concat(googleCals)[0].title, allday: res.data.concat(googleCals)[0].allday, duration: res.data.concat(googleCals)[0].duration, exdate: res.data.concat(googleCals)[0].exclusions ? res.data.concat(googleCals)[0].exclusions.split(',') : null
            //                }].filter(k => k !== "start").filter((v, i, a) => a.map(k => k.extendedProps.id).indexOf(v.extendedProps.id) == i))
            //            }
            //        })
            //    
            //
            //
            //
            //}}

            if (hCals.length > 0) {
                for (var k in hCals.filter(i => i.displayed)) {

                    api.api.get(`/api/getEventsByCalendarId/?id=${hCals.filter(i => i.displayed)[k].id}&isHome=true&email=${props.id}`)
                        .then((res) => {
                            console.log("EVENTS", res.data)
                            if (res.data.length > 1) {
                                setClientEvents(clientEvents => [...clientEvents.filter(k => k !== "start"), ...res.data.map(i => {
                                    console.log("EVENTG", res.data)
                                    return {
                                        extendedProps: {isPaid:i.isPaid,splitPaid:i.splitPaid,price:i.price,upFront:i.upFront,periodBefore:i.periodBefore,unitBefore:i.unitBefore, description: i.description, attendees: i.attendees, cname: i.cname, calid: i.calid, isGoogle: false, mId: i.mId, status: i.tags ? i.tags.length > 0 ? userTags.filter(j => j.displayed).some(p => i.tags.map(j => j.tag_id).includes(p.value)) : true : true, tags: i.tags, id: i.id, allDay: i.allday, title: i.title, start: i.start, rrule: i.rrule, duration: i.duration, },
                                        allDay: i.allday, title: i.title, start: i.start, rrule: i.rrule, duration:i.allday?null: { milliseconds: i.duration.split('-')[1] }, exdate: i.exclusions ? i.exclusions.split(',') : null
                                    }
                                })].filter(k => k !== "start").filter((v, i, a) => a.map(k => k.extendedProps.id).indexOf(v.extendedProps.id) == i))
                            } else if (res.data.length == 1) {
                                setClientEvents(clientEvents => [...clientEvents,
                                {
                                    extendedProps: {isPaid:res.data.concat(googleCals)[0].isPaid,splitPaid:res.data.concat(googleCals)[0].splitPaid,price:res.data.concat(googleCals)[0].price,upFront:res.data.concat(googleCals)[0].upFront,periodBefore:res.data.concat(googleCals)[0].periodBefore,unitBefore:res.data.concat(googleCals)[0].unitBefore, description: res.data.description, attendees: res.data.attendees, cname: res.data.cname, calid: res.data.calid, isGoogle: true, status: res.data.tags ? res.data.tags.length > 0 ? userTags.filter(j => j.displayed).some(p => res.data.tags.map(j => j.tag_id).includes(p.value)) : true : true, mId: res.data.concat(googleCals)[0].mId, mname: res.data.concat(googleCals)[0].mname, mcal: res.data.concat(googleCals)[0].mid, tags: res.data.concat(googleCals)[0].tags, id: res.data.concat(googleCals)[0].id, rrule: res.data.concat(googleCals)[0].rrule, start: res.data.concat(googleCals)[0].start, title: res.data.concat(googleCals)[0].title, allday: res.data.concat(googleCals)[0].allday, duration: res.data.concat(googleCals)[0].duration, exdate: res.data.concat(googleCals)[0].exclusions ? res.data.concat(googleCals)[0].exclusions.split(',') : null },
                                    rrule: res.data.concat(googleCals)[0].rrule, start: res.data.concat(googleCals)[0].start, title: res.data.concat(googleCals)[0].title, allday: res.data.concat(googleCals)[0].allday, duration:res.data.concat(googleCals)[0].allday?null: { milliseconds: res.data.concat(googleCals)[0].duration.split('-')[1] }, exdate: res.data.concat(googleCals)[0].exclusions ? res.data.concat(googleCals)[0].exclusions.split(',') : null
                                }].filter(k => k !== "start").filter((v, i, a) => a.map(k => k.extendedProps.id).indexOf(v.extendedProps.id) == i))
                            }
                        })
                }
            }

            for (var k in mCals.filter(i => i.displayed)) {
                api.api.get(`/api/getMEventsByCalendar/?id=${mCals.filter(i => i.displayed)[k].cid}`)
                    .then((res) => {
                        console.log(res.data)
                        setClientEvents(clientEvents => [...clientEvents.filter(k => k !== "start"), ...res.data.map(i => {
                            return {
                                extendedProps: {isPaid:i.isPaid,splitPaid:i.splitPaid,price:i.price,upFront:i.upFront,periodBefore:i.periodBefore,unitBefore:i.unitBefore, description: i.description, attendees: i.attendees, cname: i.cname, calid: i.calid, isGoogle: false, mId: i.mId, status: i.tags ? i.tags.length > 0 ? userTags.filter(j => j.displayed).some(p => i.tags.map(j => j.tag_id).includes(p.value)) : true : true, tags: i.tags, id: i.id, allDay: i.allday, title: i.title, start: i.start, rrule: i.rrule, duration: i.duration, },
                                allDay: i.allday, title: i.title, start: i.start, rrule: i.rrule, duration:i.allday?null: { milliseconds: i.duration.split('-')[1] }, exdate: i.exclusions ? i.exclusions.split(',') : null
                            }
                        })].filter(k => k !== "start").filter((v, i, a) => a.map(k => k.extendedProps.id).indexOf(v.extendedProps.id) == i))

                    }
                    )
            }
            //if (userGoogleEvents.length > 0) {
            //
            //    setClientEvents(clientEvents => [...clientEvents.filter(k=>k!=="start"), ...userGoogleEvents.map(i => {
            //        return {
            //            extendedProps: { cname: i.cname, calid: i.calid, isGoogle: true, mId: i.mId, status: i.tags ? i.tags.length > 0 ? userTags.filter(j => j.displayed).some(p => i.tags.map(j => j.tag_id).includes(p.value)) : true : true, tags: i.tags, id: i.id, allDay: i.allday, title: i.title, start: i.start, rrule: i.rrule, duration: i.duration, },
            //            allDay: i.allday, title: i.title, start: i.start, rrule: i.rrule, duration: i.duration, exdate: i.exclusions ? i.exclusions.split(',') : null
            //        }
            //
            //    }
            //    )].filter(k => k !== "start").filter((v, i, a) => a.map(k => k.extendedProps.id).indexOf(v.extendedProps.id) == i))
            //}

            api.api.get(`/api/getApparentMilieuSubEvents?email=${user.email}&user=${props.id}`)
                .then(res => setClientEvents(clientEvents => [...clientEvents.filter(k => k !== "start"), ...res.data.map(i => {
                    return {
                        extendedProps: {isPaid:i.isPaid,splitPaid:i.splitPaid,price:i.price,upFront:i.upFront,periodBefore:i.periodBefore,unitBefore:i.unitBefore, description: i.description, attendees: i.attendees, cname: i.cname, calid: i.calid, isGoogle: false, mId: i.mId, status: i.tags ? i.tags.length > 0 ? userTags.filter(j => j.displayed).some(p => i.tags.map(j => j.tag_id).includes(p.value)) : true : true, tags: i.tags, id: i.id, allDay: i.allday, title: i.title, start: i.start, rrule: i.rrule, duration: i.duration, },
                        allDay: i.allday, title: i.title, start: i.start, rrule: i.rrule, duration:i.allday?null: { milliseconds: i.duration.split('-')[1] }, exdate: i.exclusions ? i.exclusions.split(',') : null
                    }
                })].filter(k => k !== "start").filter((v, i, a) => a.map(k => k.extendedProps.id).indexOf(v.extendedProps.id) == i))
                )

            api.api.get(`/api/getApparentUserSubEvents?email=${user.email}&user=${props.id}`)
                .then(res => setClientEvents(clientEvents => [...clientEvents.filter(k => k !== "start"), ...res.data.map(i => {
                    return {
                        extendedProps: {isPaid:i.isPaid,splitPaid:i.splitPaid,price:i.price,upFront:i.upFront,periodBefore:i.periodBefore,unitBefore:i.unitBefore, description: i.description, attendees: i.attendees, cname: i.cname, calid: i.calid, isGoogle: false, mId: i.mId, status: i.tags ? i.tags.length > 0 ? userTags.filter(j => j.displayed).some(p => i.tags.map(j => j.tag_id).includes(p.value)) : true : true, tags: i.tags, id: i.id, allDay: i.allday, title: i.title, start: i.start, rrule: i.rrule, duration: i.duration, },
                        allDay: i.allday, title: i.title, start: i.start, rrule: i.rrule, duration: i.allday?null:{ milliseconds: i.duration.split('-')[1] }, exdate: i.exclusions ? i.exclusions.split(',') : null
                    }
                })].filter(k => k !== "start").filter((v, i, a) => a.map(k => k.extendedProps.id).indexOf(v.extendedProps.id) == i))
                )

            if ([...hCals, ...mCals.filter(i => i.displayed)].length == 0) {
                setClientEvents([])
            }
            //if (googleSubEvents.length > 0) {
            //
            //    setClientEvents(clientEvents => [...clientEvents, ...googleSubEvents.map(i => {
            //        return {
            //            extendedProps: { cname: i.cname, calid: i.calid, isGoogle: true, mId: i.mId, status: i.tags ? i.tags.length > 0 ? userTags.filter(j => j.displayed).some(p => i.tags.map(j => j.tag_id).includes(p.tags_id)) : true : true, tags: i.tags, id: i.id, allDay: i.allday, title: i.title, start: i.start, rrule: i.rrule, duration: i.duration, },
            //            allDay: i.allday, title: i.title, start: i.start, rrule: i.rrule, duration: i.duration, exdate: i.exclusions ? i.exclusions.split(',') : null
            //        }
            //
            //    }
            //    )].filter(k => k !== "start").filter((v, i, a) => a.map(k => k.extendedProps.id).indexOf(v.extendedProps.id) == i))
            //}

        }


        if (props.mode == "milieu" && clientEvents[0] == "start") {

            for (var k in milieuCals.filter(j => j.displayed)) {
                api.api.get(`/api/getMEventsByCalendar/?id=${milieuCals.filter(j => j.displayed)[k].id}`)
                    .then((res) => {
                        console.log("RESDATA", res.data)
                        setClientEvents(clientEvents => [...clientEvents.filter(k => k !== "start"), ...res.data.map(i => {
                            return {
                                extendedProps: {isPaid:i.isPaid,splitPaid:i.splitPaid,price:i.price,upFront:i.upFront,periodBefore:i.periodBefore,unitBefore:i.unitBefore, description: i.description, attendees: i.attendees, cname: i.cname, calid: i.calid, isGoogle: false, mId: i.mId, status: i.tags ? i.tags.length > 0 ? userTags.filter(j => j.displayed).some(p => i.tags.map(j => j.tag_id).includes(p.value)) : true : true, tags: i.tags, id: i.id, allDay: i.allday, title: i.title, start: i.start, rrule: i.rrule, duration: i.duration, },
                                allDay: i.allday, title: i.title, start: i.start, rrule: i.rrule, duration:i.allday?null: { milliseconds: i.duration.split('-')[1] }, exdate: i.exclusions ? i.exclusions.split(',') : null
                            }
                        })].filter(k => k !== "start").filter((v, i, a) => a.map(k => k.extendedProps.id).indexOf(v.extendedProps.id) == i))

                    }
                    )
            }

        }


        console.log(clientEvents)
    }, [check, events, clientEvents])
    const setcal = (calendar) => {
        setCal(calendar)
    }
    const handleEdit = (mode, event) => {
        if (props.mode == "home") {
            setEvent(event)
            setEdit(true)
            setMode(mode)
        }
        else if (props.mode == "milieu") {

            setEvent(event)
            setEdit(true)
            setMode(mode)
        }
    }

    const handleEventClick = (e) => {
        setModal(!modal)
        setParticular(e.event)
        setEventData(e.event.extendedProps)
    }
    useEffect(() => {
        console.log(modal)
    }, [modal])
    return (
        <div>

            <div className='setup'>
                {props.mode == "milieu" ?
                    <EventSetup cal={cal} tags={tags} user={props.user} reset={reset} id={props.id} mode={props.mode} events={events} setEvents={setEvents} />
                    : <EventSetup cal={cal} tags={tags} reset={reset} id={null} mode={props.mode} events={events} setEvents={setEvents} />}
            </div>
            <div className='cal'>

                <FullCalendar
                    in
                    timeZone='local'
                    eventClick={(e) => { handleEventClick(e) }}
                    plugins={[rrulePlugin, dayGridPlugin, momentTimezonePlugin, timeGridPlugin, interactionPlugin]}
                    initialView="dayGridMonth"

                    headerToolbar={{
                        start: 'timeGridDay,timeGridWeek dayGridMonth', // will normally be on the left. if RTL, will be on the right
                        center: 'title',
                        end: 'today prev,next' // will normally be on the right. if RTL, will be on the left
                    }}
                    events={props.mode == "home" ? events : clientEvents.length > 0 ? clientEvents.filter(i => i !== "start").filter(i => i.extendedProps.status) : []}
                />
            </div>
            <CalendarTab mname={props.mname} displayedMilieuCals={displayedMilieuCals} displayedSubCals={displayedSubCals} displayedCals={displayedCals} displayedUserTags={displayedUserTags} setDisplayedUserTags={setDisplayedUserTags} userGoogleSubs={userGoogleSubs} setUserGoogleSubs={setUserGoogleSubs} userSubs={userSubs} setUserSubs={setUserSubs} googleSubs={googleSubs} setGoogleSubs={setGoogleSubs} displayedSubEvents={displayedSubEvents} setDisplayedSubEvents={setDisplayedSubEvents} userTags={userTags} setUserTags={setUserTags} clientEvents={clientEvents} setUserGoogleEvents={setUserGoogleEvents} setUserGoogleCals={setUserGoogleCals} hCals={hCals} setHCals={setHCals} mCals={mCals} setMCals={setMCals} milieuCals={milieuCals} setMilieuCals={setMilieuCals} tags={tags} addGoogleCals={addGoogleCals} calPub={calPub} googleCalPub={googleCalPub} setClientEvents={setClientEvents} cEvents={clientEvents} setmemberstatus={setMemberStatus} id={props.id} cal={cal} mode={props.mode} reset={reset} setCal={setcal} setEvents={setEvents} />
            <div >
                <Modal style={{ padding: '100px' }} show={modal} fullscreen={true} >
                    <Modal.Body>

                        <div >
                            <Event reset={reset} setGoogleEvents={setGoogleCals} cal={cal} memberStatus={memberStatus} mode={props.mode} handleEdit={handleEdit} modal={modal} setModal={setModal} eventData={eventData} particular={particular} />
                        </div>


                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant='primary' onClick={() => { setModal(!modal) }}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>


            {modal && (
                <div className="modal">
                    <div className='overlay'>
                    </div>
                    <div className='modal-content'>
                        <Event stripe={props.stripe} reset={reset} setGoogleEvents={setGoogleCals} cal={cal} memberStatus={memberStatus} mode={props.mode} handleEdit={handleEdit} modal={modal} setModal={setModal} eventData={eventData} particular={particular} />
                        <button onClick={() => { setModal(!modal) }}>
                            Close
                        </button>
                    </div>
                </div>
            )}
            <EventEdit tags={tags} setGoogleEvents={setGoogleCals} eventData={eventData} reset={reset} cal={cal} pos={props.mode} particular={particular} event={event} edit={edit} modal={edit} series={mode} setEdit={setEdit} />
        </div>
    )
}