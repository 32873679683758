import React, { useEffect,useState,useContext } from "react";
import { auth } from "../../firebase/firebase";
import { onAuthStateChanged } from "firebase/auth";
const AuthContext = React.createContext();

export function useAuth(){
    return useContext(AuthContext)
}
export function AuthProvider({children}){
    const [user,setUser] = useState(null)
    const [isAuthenticated,setIsAuthenticated] = useState(false)
    const [isLoading,setIsLoading] = useState(true)

    useEffect(()=>{
        const unsubscribe = onAuthStateChanged(auth,initializeUser)
        return unsubscribe
    },[])

    async function initializeUser(user){
        if(user){
            setUser({...user})
            setIsAuthenticated(true)
        }else{
            setUser(null)
            setIsAuthenticated(false)
        }
        setIsLoading(false)


    }
    const value = {
        user,
        isAuthenticated,
        isLoading
    }
    return(
        <AuthContext.Provider value={value}>
            {!isLoading && children}
        </AuthContext.Provider>
    )

}