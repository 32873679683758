import Cal from '../components/Cal.js'
import NavBar from '../components/NavBar.js'
import axios  from 'axios';
import React, {useState,useEffect} from 'react'
import { useAuth } from '../contexts/authContext/index.js';
import { useParams } from "react-router-dom";
import SideBar from '../components/SideBar.js';
import { useApi } from '../config.js'
export default function Milieu(props){
    var api = useApi();
    var { user, isAuthenticated, isLoading }  = useAuth()
    var {id,name} = useParams();
    const [toggle,setToggle] = useState(false)
    useEffect(()=>{
        if(isAuthenticated){
            api.api.post('/api/signup',{email:user.email})
        }},[isLoading])

        
    if (isLoading) {
        return <div>Loading ...</div>;
    }
    return(

        <>  
                      <NavBar/>
  

                    <div className='holder'>
                    <SideBar toggle={toggle} onClick={()=>{setToggle(!toggle)}} />

                    <div className='calendar'>
                        <Cal mode={'milieu'}  user={user} id={id} mname={name}/>
                    </div>
                    </div>
        </>
    )
}