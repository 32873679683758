import Cal from '../components/Cal.js'
import NavBar from '../components/NavBar.js'
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { CgCommunity } from "react-icons/cg";
import { HiMiniUserGroup } from "react-icons/hi2";
import { MdGroupAdd } from "react-icons/md";
import moment from 'moment'
import "../css/modal.css"
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Select from 'react-select'
import { FaUserFriends } from "react-icons/fa";
import { BiSolidMessageAltDetail } from "react-icons/bi";
import { IoCalendarNumber } from "react-icons/io5";
import { IoIosCreate } from "react-icons/io";
import { TbCalendarPlus } from "react-icons/tb";
import "../css/home.css"
import { datetime, RRule, RRuleSet, rrulestr } from 'rrule'
import { useApi } from '../config.js'
import { useAuth } from '../contexts/authContext/index.js';
import SideBarItem from '../components/SideBarItem.js';
import SideBar from '../components/SideBar.js'
import { useNavigate } from "react-router-dom";
export default function Messages(props) {
    const api = useApi()
    const navigate = useNavigate()
    const [membReqs, setMembReqs] = useState([])
    const { user, isAuthenticated, isLoading } = useAuth();
    const [check, setCheck] = useState(0)
    const [gids, setGids] = useState([])
    const [gInvites, setGInvites] = useState([])
    const [events, setEvents] = useState([])
    const [event, setEvent] = useState({})
    const [calendarsModal, setCalendarsModal] = useState(false)
    const [calendars, setCalendars] = useState([])
    const [selectedCalendar, setSelectedCalendar] = useState("")
    const [milieuInvites, setMilieuInvites] = useState([])

    const handleAcceptMilieuInvite = (id) => {
        api.api.post(`/api/acceptMilieuInvite`, { email: user.email, id: id })
            .then(res => reset())
    }
    const handleIgnoreMilieuInvite = (id) => {
        api.api.post(`/api/ignoreMilieuInvite`, { email: user.email, id: id })
            .then(res => reset())
    }
    const reset = () => {

        api.api.get(`/api/getMilieuInvites/?email=${user.email}`)
            .then(res => setMilieuInvites(res.data))
        api.api.get(`/api/getMemberReqs/?email=${user.email}`)
            .then((res) => setMembReqs(res.data))
        api.api.get(`/api/getInvites/?email=${user.email}`)
            .then((res) => {
                setGids(res.data.gids)
                setEvents(res.data.events)
            })
        api.api.get(`/api/getUserMilieuCalendars/?email=${user.email}`)
            .then(res => setCalendars(res.data))


    }
    useEffect(() => {
        if (isAuthenticated) {
            api.api.get(`/api/getMilieuInvites/?email=${user.email}`)
                .then(res => setMilieuInvites(res.data))
            api.api.get(`/api/getMemberReqs/?email=${user.email}`)
                .then((res) => setMembReqs(res.data))
            api.api.get(`/api/getInvites/?email=${user.email}`)
                .then((res) => {
                    console.log("RES", res.data)
                    setGids(res.data.gids)
                    setEvents(res.data.events)
                })
            api.api.get(`/api/getUserMilieuCalendars/?email=${user.email}`)
                .then(res => setCalendars(res.data))
        }
    }, [])
    const handleIgnore = (email, id) => {
        api.api.post(`/api/ignoreMembReq/`, { email: email, id: id })
    }
    const handleAccept = (email, id) => {
        api.api.post(`/api/acceptMembReq`, { email: email, id: id })
    }

    const handleIgnoreConfirm = (event) => {
        api.api.post(`/api/removeInvite`, { email: user.email, event: { extendedProps: { id: event.id, calid: event.calid } } })
            .then(res => reset())
    }
    const handleConfirm = () => {
        console.log(event)


        api.api.post(`/api/attendSeries`, { calid: selectedCalendar.value, email: user.email, event: { extendedProps: { id: event.id, calid: event.calid } } })
            .then(res => {
                api.api.post(`/api/removeInvite`, { email: user.email, event: { extendedProps: { id: event.id, calid: event.calid } } })
                    .then(resp => reset())
            }
            )

        console.log(selectedCalendar)
    }
    if (isLoading) {
        return <div>Loading ...</div>;
    }
    return (

        <div>
            {isAuthenticated && (
                <div>
                    <NavBar />
                    <SideBar />
                    <h1>Notifications</h1>
                    <div>
                        <h2>Milieu Requests</h2>
                        {membReqs.map(i => `${i.milieus_id}-${i.name}`).filter((v, i, a) =>
                            a.indexOf(v) == i
                        ).map(i =>
                            <div>
                                <h3>{i.split('-')[1]}</h3>
                                {membReqs.filter(j => `${j.milieus_id}` == `${i.split('-')[0]}`).map(j =>

                                    <div>
                                        {j.email}
                                        <div>
                                            <button onClick={() => { handleAccept(j.email, j.milieus_id) }}>Accept</button>
                                            <button onClick={() => { handleIgnore(j.email, j.milieus_id) }}>Ignore</button>
                                        </div>
                                    </div>

                                )}
                            </div>
                        )}
                    </div>
                    <div>
                        <h2>Milieu Invites</h2>
                        {milieuInvites.map(i => `${i.id}-${i.name}`).filter((v, i, a) =>
                            a.indexOf(v) == i
                        ).map(i =>
                            <div>
                                <h3>{i.split('-')[1]}</h3>
                                {milieuInvites.filter(j => `${j.id}` == `${i.split('-')[0]}`).map(j =>

                                    <div>
                                        {j.name}
                                        <div>
                                            <button onClick={() => { handleAcceptMilieuInvite(j.id) }}>Accept</button>
                                            <button onClick={() => { handleIgnoreMilieuInvite(j.id) }}>Ignore</button>
                                        </div>
                                    </div>

                                )}
                            </div>
                        )}
                    </div>
                    <div>
                        <h2>Invites</h2>
                        {events.map(i =>


                            <div>
                                <div>
                                    <div>{i.title}</div>
                                    <div>{i.rrule ? RRule.fromString(i.rrule).toText() : i.duration ?
                                        `${moment(i.start).format('MMMM Do YYYY, h:mm:ss a')}
                                        - ${moment(i.start).add(i.duration.split('-')[1], "milliseconds").format('MMMM Do YYYY, h:mm:ss a')}`
                                        : `${moment(i.start).format('MMMM Do YYYY, h:mm:ss a')} | All Day`
                                    }</div>
                                </div>

                                <button onClick={() => { setEvent(i); setCalendarsModal(true) }}>Attend</button>
                                <button onClick={() => { handleIgnoreConfirm(i) }}>Ignore</button>
                            </div>






                        )}
                    </div>

                    <Modal style={{marginTop:"10%"}} show={calendarsModal} >
                        <Modal.Header closeButton>
                            <Modal.Title>Modal heading</Modal.Title>
                        </Modal.Header>
                        <Modal.Body  style={{ height:"100px", marginTop:"10%"}}>

                            <h2>Select A Milieu Calendar</h2>

                            <Select
                                value={selectedCalendar}
                                onChange={(e) => { setSelectedCalendar(e) }}
                                options={calendars.filter(i => i.role ? i.role == "writer" || i.role == "owner" : true).map(i => { return { value: i.id, label: i.name ? i.name : "untitled" } })}
                            />

                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={() => setCalendarsModal(!calendarsModal)}>
                                Close
                            </Button>
                            <Button variant="primary" onClick={() => handleConfirm()}>
                                Confirm
                            </Button>
                        </Modal.Footer>
                    </Modal>
                </div>)

            }
        </div>
    )
}