import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './css/modal.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import App from './App';

import reportWebVitals from './reportWebVitals';
import { Auth0Provider } from "@auth0/auth0-react"
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(

  <React.StrictMode>
    <Auth0Provider
      domain="dev-qn7f273ltr62ziop.us.auth0.com"
      clientId="t8VZxChWXjZCWLAXVqdltxkoLp1kNE2F"
      useRefreshTokens={true}
      cacheLocation="localstorage"
      authorizationParams={{
        connection_scope: ['https://www.googleapis.com/auth/calendar', 'https://www.googleapis.com/auth/calendar.events'],
        access_type: 'offline',
        approval_prompt: 'force'
      }}>
     
        <App  />
      
    </Auth0Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//https://milieu.social/home
//http://localhost:3000/home
reportWebVitals();
