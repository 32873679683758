import axios from "axios";
import { useContext, createContext } from 'react';

export const apiContext = createContext();

const ApiProvider = ({ children }) => {

  var api = axios.create({ 
    //baseURL: "https://milieu-7769b1d2eb44.herokuapp.com/",
    baseURL: "http://localhost:5000"
  })

  return (
    <apiContext.Provider value={{api}}>
      {children}
    </apiContext.Provider>
  )
}
export default ApiProvider

export const useApi = () => {
  return useContext(apiContext);
}
//http://localhost:5000
//https://milieu.social:5000