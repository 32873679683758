

import axios from '../../api/axios.js'
import { useApi } from '../../config.js';
import React, { useContext, createContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import moment from 'moment';
import DatePicker from "react-multi-date-picker";
import '../../css/event.css'
import Nav from 'react-bootstrap/Nav';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Card from 'react-bootstrap/Card';
import ListGroup from 'react-bootstrap/ListGroup';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Form from 'react-bootstrap/Form';
import { useAuth } from '../../contexts/authContext/index.js';
import { useParams } from 'react-router-dom';
import "../../css/inviteModal.css"
import Select from 'react-select'
import { TagsInput } from "react-tag-input-component"
const Comment = (commentProps) => {
    const api = useApi()
    const { user } = useAuth()
    const [comment, setComment] = useState({})
    const [children, setChildren] = useState([])
    const [replyToggle, setReplyToggle] = useState(false)
    const [reply, setReply] = useState("")

    useEffect(() => {
        
        api.api.get(`/api/getComment/?id=${commentProps.id}`)
            .then(res => setComment(res.data))
        api.api.get(`/api/getCommentChildren/?id=${commentProps.id}`)
            .then(res => setChildren(res.data))
    }, [])
    useEffect(() => {
        console.log(children)
    }, [children])
    const handleSend = () => {

        if (commentProps.isGoogle) {
            api.api.post(`/api/replyGoogleComment/`, { parent: commentProps.id, comment: reply, email: user.email, id: commentProps.userevents_id })
                .then(res => commentProps.reset())

        } else {
            api.api.post(`/api/replyComment/`, { parent: commentProps.id, comment: reply, email: user.email, id: commentProps.userevents_id })
                .then(res => commentProps.reset())
        }
    }
    return (
        <div>

            <div style={{ margin: "10px" }}>
                <div>
                    {comment.comment}
                </div>
                <div >
                    <div>
                        {comment.users_email}
                    </div>
                    <div>
                        {moment(comment.time).format('YYYY-MM-DD, h:mm:ss a')}
                    </div>
                </div>
            </div>
            <div>
                <Button variant='primary' onClick={() => { commentProps.setSustain(commentProps.sustain + 1); setReplyToggle(!replyToggle) }}>Reply</Button>
                <Form.Control as="textarea" cols="2" onClick={() => { commentProps.setSustain(commentProps.sustain + 1) }} onChange={(e) => setReply(e.target.value)} className={replyToggle ? "replyactive" : "reply"} />
                <div>
                    <Button variant='primary' className={replyToggle ? "replyactive" : "reply"} onClick={handleSend}>Comment</Button>
                </div>

            </div>


            <div>
                {children.map(i =>
                    <Comment isGoogle={commentProps.isGoogle} userevents_id={commentProps.userevents_id} reset={commentProps.reset} sustain={commentProps.sustain} setSustain={commentProps.setSustain} toggleComments={commentProps.toggleComments} setTogglecomments={commentProps.setTogglecomments} id={i.child_comments_id} />
                )}
            </div>
        </div>
    )
}
export default function Event(props) {
    const [comment, setComment] = useState("")
    const [createCommentToggle, setCreateCommentToggle] = useState(false)
    const [modal, setModal] = useState(false);
    const [milieu, setMilieu] = useState("")
    const [edittoggle, setEditToggle] = useState(true)
    const [deletetoggle, setDeleteToggle] = useState(false)
    const { user } = useAuth()
    const [attendToggle, setAttendToggle] = useState(false)
    const [inviteesToggle, setInviteesToggle] = useState(false)
    const api = useApi()
    const [calendarsModal, setCalendarsModal] = useState(false)
    const [calendars, setCalendars] = useState([])
    const [selectedCalendar, setSelectedCalendar] = useState({})
    const [mode, setMode] = useState(false)
    const [attendance, setAttendance] = useState([])
    const [togglecomments, setTogglecomments] = useState(false)
    const [comments, setComments] = useState([])
    const [checker, setchecker] = useState(0)
    const [sustain, setSustain] = useState(0)
    const [series, setSeries] = useState(false)
    const [single, setSingle] = useState(false)
    const [inviteModal, setInviteModal] = useState(false)
    const [invitees, setInvitees] = useState([])
    const [contacts, setContacts] = useState([])
    const [selectedUncontacted, setSelectedUncontacted] = useState([])
    const [selectedContacted, setSelectedContacted] = useState([])
    const [attendants, setAttendants] = useState([])
    const [userDetails, setUserDetails] = useState({})
    const [paymentMethods, setPaymentMethods] = useState([])
    const navigate = useNavigate()
    const [payDate, setPayDate] = useState(moment(new Date()).format('MMMM Do YYYY, h:mm a'))
    const [payMessage, setPayMessage] = useState("")
    const [paySeriesTip,setPaySeriesTip] = useState("")
    const [toolTipMessage, setToolTipMessage] = useState("")
    const [owner,setOwner] = useState({})

    useEffect(() => {
        api.api.get(`/api/getOwner/?eid=${props.particular.extendedProps.id}`)
        .then(res=>setOwner(res.data[0]))
        if (props.particular.extendedProps.isPaid) {

            if (props.particular.extendedProps.splitPaid) {


                var s = moment(props.particular.start)
                var time
                switch (`${props.particular.extendedProps.unitBefore}`) {
                    case "0":
                        time = s.subtract(props.particular.extendedProps.periodBefore, "minutes").format('MMMM Do YYYY, h:mm a')
                        break;
                    case "1":
                        time = s.subtract(props.particular.extendedProps.periodBefore, "hours").format('MMMM Do YYYY, h:mm a')
                        break;
                    case "2":
                        time = s.subtract(props.particular.extendedProps.periodBefore, "days").format('MMMM Do YYYY, h:mm a')
                        break;
                }

                var unit
                switch (`${props.particular.extendedProps.unitBefore}`) {
                    case "0":
                        unit = props.particular.extendedProps.periodBefore>1? "minutes":"minute"
                        break;
                    case "1":
                        unit = props.particular.extendedProps.periodBefore>1? "hours":"hour"
                        break;
                    case "2":
                        unit = props.particular.extendedProps.periodBefore>1? "days":"day"
                        break;
                }

                setPayMessage(`Total Cost to be split: ${props.particular.extendedProps.price}`)
                setPayDate(time)
                setToolTipMessage(`${props.particular.extendedProps.price} dollars will be split equally and charged amongst all attendants at the following time: ${time}`)
                setPaySeriesTip(`${props.particular.extendedProps.price} dollars will be split equally and charged amongst all attendants ${props.particular.extendedProps.periodBefore} ${unit} before each event.`)

            }



            if (!props.particular.extendedProps.splitPaid) {
                if (props.particular.extendedProps.upFront) {
                    setPayDate("Now")
                    setPayMessage(`Entry Fee: ${props.particular.extendedProps.price} ⋅ Up Front`)
                    setToolTipMessage(`Attending this event will charge ${props.particular.extendedProps.price} to your default card`)
                } else {
                    var s = moment(props.particular.start)
                    var time
                    switch (`${props.particular.extendedProps.unitBefore}`) {
                        case "0":
                            time = s.subtract(props.particular.extendedProps.periodBefore, "minutes").format('MMMM Do YYYY, h:mm a')
                            break;
                        case "1":
                            time = s.subtract(props.particular.extendedProps.periodBefore, "hours").format('MMMM Do YYYY, h:mm a')
                            break;
                        case "2":
                            time = s.subtract(props.particular.extendedProps.periodBefore, "days").format('MMMM Do YYYY, h:mm a')
                            break;
                    }

                    var unit
                    switch (`${props.particular.extendedProps.unitBefore}`) {
                        case "0":
                            unit = props.particular.extendedProps.periodBefore>1? "minutes":"minute"
                            break;
                        case "1":
                            unit = props.particular.extendedProps.periodBefore>1? "hours":"hour"
                            break;
                        case "2":
                            unit = props.particular.extendedProps.periodBefore>1? "days":"day"
                            break;
                    }
                    setPaySeriesTip(`${props.particular.extendedProps.price} dollars will be charged to your default card, ${props.particular.extendedProps.periodBefore} ${unit} before each event.`)
                    setPayMessage(`Entry Fee: ${props.particular.extendedProps.price}`)
                    setPayDate(time)
                    setToolTipMessage(`Attending this event will charge ${props.particular.extendedProps.price} to your default card at the following time:
                    ${time}`)
                }

            }


        }


        api.api.get(`/api/getUserDetails?email=${user.email}`)
            .then(res => setUserDetails(res.data))
        api.api.get(`/api/getPaymentMethods?email=${user.email}`)
            .then(res => setPaymentMethods(res.data))
        if (props.eventData.mId) {
            api.api.get(`/api/getMilieuById/?id=${props.eventData.mId}`)
                .then((res) => setMilieu(res.data[0].name))
        }
        api.api.get(`/api/getUserMilieuCalendars/?email=${user.email}`)
            .then(res => setCalendars(res.data))
        api.api.get(`/api/getAttendants/?id=${props.particular.extendedProps.id}`)
            .then(res => setAttendants(res.data))
        api.api.get(`/api/getAttendance/?email=${user.email}`)
            .then(res => { setAttendance(res.data) })

        if (props.particular.extendedProps.isGoogle) {
            api.api.get(`/api/getGoogleComments/?id=${props.particular.extendedProps.id}`)
                .then(res => setComments(res.data))
        } else {
            api.api.get(`/api/getComments/?id=${props.particular.extendedProps.id}`)
                .then(res => setComments(res.data))
        }
        api.api.get(`/api/getAllContacts/?email=${user.email}`)
            .then((res) => setContacts(res.data.map(i => { return { value: i, label: i } })))
        console.log(props.particular)
    }, [props])

    useEffect(() => {
        if (attendance.length > 0) {
            console.log(attendance)
            if (attendance.filter(i => i.users_email == user.email).length > 0) {
                var yours = attendance.filter(i => i.users_email == user.email)
                console.log("YOUR",yours)
                if (yours.map(i => i.userevents_id).includes(props.particular.extendedProps.id)) {
                    var yoursthisevent = yours.filter(i => i.userevents_id == props.particular.extendedProps.id)
                    console.log("YOURS",yoursthisevent)
                    if (yoursthisevent.map(i => i.date).join(',').split(',').length == 0 || (yoursthisevent.map(i => i.date).join(',').split(',').length == 1 && yoursthisevent.map(i => i.date).join(',').split(',')[0] == "")) {
                        setSeries(false)
                        
                    } else {
                        console.log("DATES", yoursthisevent.map(i => i.date).join(',').split(','))
                        setSeries(true)
                    }

                    if (yoursthisevent.map(i => i.date).join(',').split(',').includes(moment(props.particular.start).format("YYYY-MM-DD"))) {
                        setSingle(false)
                    } else {
                        if (yoursthisevent.map(i => i.date).join(',').split(',').length == 0 || yoursthisevent.map(i => i.date).join(',').split(',').length == 1 && yoursthisevent.map(i => i.date).join(',').split(',')[0] == "") {
                        } else {


                            setSingle(true)
                        }
                    }


                } else {

                    setSeries(true)
                    setSingle(true)
                }
            } else {
                setSeries(true)
                setSingle(true)
            }
        } else {
            setSeries(true)
            setSingle(true)
        }

    }, [attendance])
    useEffect(() => {
        console.log("series", series)
        if (!series) { setSingle("not") }
    }, [series])
    useEffect(() => {
        console.log("Single", single)
    }, [single])
    useEffect(() => {

        if (sustain == checker) { return } else {
            setTogglecomments(true); setchecker(sustain)
        }
    }, [togglecomments, sustain, checker])
    const toggleComments = () => {
        setTogglecomments(!togglecomments)
    }
    const handleSingle = () => {
        props.setModal(!props.modal)
        props.handleEdit("single", props.particular)

    }
    const handleInvite = () => {
        setInviteModal(true)
    }
    const handleConfirmInvite = async () => {
        const inv = [...selectedContacted.map(i => i.value), ...selectedUncontacted]


        api.api.post(`/api/invite`, { invitees: inv, id: props.particular.extendedProps.id, email: user.email })

        api.api.post('/api/emailInvite', { invitees: inv, id: props.particular.extendedProps.id })
            .then(res => {

                for (var i in res.data.userdata) {
                    fetch("/.netlify/functions/hello-world", {
                        method: "POST",
                        body: JSON.stringify({
                            participation: res.data.participation,
                            invited: res.data.userdata[i].mode == "signup" ? res.data.userdata[i].user.slice(1, res.data.userdata[i].user.length - 1) : JSON.parse(res.data.userdata[i].user).email,
                            invited_fName: res.data.userdata[i].mode == "signup" ? null : JSON.parse(res.data.userdata[i].user).first_name,
                            invited_lName: res.data.userdata[i].mode == "signup" ? null : JSON.parse(res.data.userdata[i].user).last_name,
                            mode: res.data.userdata[i].mode,
                            inviter: user.email,
                            id: props.particular.extendedProps.id,
                            start: props.particular.extendedProps.start,
                            duration: props.particular.extendedProps.duration,
                            rrule: props.particular.extendedProps.rrule,
                            title: props.particular.extendedProps.title
                        })
                    }).then(res => setInviteModal(false)).catch(e => console.log(e))
                }
            }
            )
        setInviteModal(false)
    }
    const handleSeries = () => {

        props.setModal(!props.modal)
        props.handleEdit("series", props.particular, props.mode)
    }
    const handleAttendSeries = async () => {
        setMode(true)
        console.log(attendance)

        if (props.particular.extendedProps.isGoogle) {

            series ? setCalendarsModal(true) :
                api.api.post('/api/stopSeriesGoogleAttendance', { email: user.email, geventId: props.particular.extendedProps.id })

        } else {




            //if (props.particular.extendedProps.isPaid) {
//
            //    if (paymentMethods.length == 0) {
//
//
            //        await fetch("/.netlify/functions/stripe-future", {
            //            method: "POST",
            //            body: JSON.stringify({
            //                email: user.email,
            //            })
            //        }).then(async res => {
            //            const result = await res.json()
            //            window.location.replace(result.url)
            //        })
            //    } else {
            //        var method
            //        if (paymentMethods.filter(i => i.isDef).length == 0) {
            //            method = paymentMethods[0].paymentMethod
            //        } else {
            //            method = paymentMethods.filter(i => i.isDef)[0].paymentMethod
            //        }
            //        await fetch("/.netlify/functions/stripe-future-process", {
            //            method: "POST",
            //            body: JSON.stringify({
            //                ownerAcc:owner.stripeAccount,
            //                email: user.email,
            //                price: props.particular.extendedProps.price,
            //                paymentMethod: method
            //            })
            //        }).then(async res => {
            //            console.log(res)
            //        })
//
//
            //    }
//
            //}

            series ? setCalendarsModal(true) :
                api.api.post('/api/stopSeriesAttendance', { email: user.email, id: props.particular.extendedProps.id })
        }

        reset()
        props.reset()
    }
    const handleAttendSingle = async () => {
        setMode(false)

        if (props.particular.extendedProps.isGoogle) {

            single ? setCalendarsModal(true) :
                api.api.post('/api/stopSingleGoogleAttendance', { email: user.email, geventId: props.particular.extendedProps.id, date: props.particular.start })

        }else{
            if(props.particular.extendedProps.rrule){
                setMode(false)
            }else{
                setMode(true)
            }
            

            single ? setCalendarsModal(true) :
                api.api.post('/api/stopAttendingSingle', { email: user.email, id: props.particular.extendedProps.id, date: moment.utc(props.particular.start).format("YYYY-MM-DD") })
        }
        reset()
        props.reset()
    }
    useEffect(()=>{
        console.log("OWNER",owner)
    },[owner])
    const handleConfirm = async () => {

        if (props.particular.extendedProps.isPaid) {
            if (!props.particular.extendedProps.upFront) {
                if (paymentMethods.length == 0) {
                    
                await fetch("/.netlify/functions/stripe-future", {
                    method: "POST",
                    body: JSON.stringify({
                        email: user.email,
                    })
                }).then(async res => {
                    const result = await res.json()
                    window.location.replace(result.url)
                })
            }
            } else {

                console.log(paymentMethods)
                if (paymentMethods.length == 0) {
                    
                    await fetch("/.netlify/functions/stripe-future", {
                        method: "POST",
                        body: JSON.stringify({
                            email: user.email,
                        })
                    }).then(async res => {
                        const result = await res.json()
                        window.location.replace(result.url)
                    })
                } else {
                    var method
                    if (paymentMethods.filter(i => i.isDef).length == 0) {
                        method = paymentMethods[0].paymentMethod
                    } else {
                        method = paymentMethods.filter(i => i.isDef)[0].paymentMethod
                    }
                    await fetch("/.netlify/functions/stripe-future-process", {
                        method: "POST",
                        body: JSON.stringify({
                            ownerAcc:owner.stripeAccount,
                            email: user.email,
                            price: props.particular.extendedProps.price,
                            paymentMethod: method
                        })
                    }).then(async res => {
                        const result = await res.json()
                        if(result.status=="succeeded"){
                        if (mode) {
                            api.api.post('/api/attendSeries', { email: user.email, calid: props.particular.extendedProps.calid, id: props.particular.extendedProps.id, selectCal: selectedCalendar.value,event:props.particular,paid:true,event:props.particular})
                                .then(res => {
                                    setCalendarsModal(false)
                                    props.reset()
                                })
                        } else {
            
                            api.api.post('/api/attendSingle', { date: moment.utc(props.particular.start).format("YYYY-MM-DD"), email: user.email, id: props.particular.extendedProps.id, calid: props.particular.extendedProps.calid, selectCal: selectedCalendar.value,paid:true })
                                .then(res => {
                                    reset()
                                    setCalendarsModal(false)
                                    props.reset()
                                })
                        }
                    }
                        api.api.post('/api/setPaid',{email:user.email,status:result.status })
                    })
                }
            }
        }else{


            if (mode) {
                api.api.post('/api/attendSeries', { email: user.email, calid: props.particular.extendedProps.calid, id: props.particular.extendedProps.id, selectCal: selectedCalendar.value,event:props.particular })
                    .then(res => {
                        setCalendarsModal(false)
                        props.reset()
                    })
            } else {

                api.api.post('/api/attendSingle', { date: moment.utc(props.particular.start).format("YYYY-MM-DD"), email: user.email, id: props.particular.extendedProps.id, calid: props.particular.extendedProps.calid, selectCal: selectedCalendar.value })
                    .then(res => {
                        reset()
                        setCalendarsModal(false)
                        props.reset()
                    })
            }
        }
        if (props.particular.extendedProps.isGoogle) {

            if (!mode) {
                api.api.post('/api/attendGoogleSingle', { selectCal: selectedCalendar.value, email: user.email, calid: props.particular.extendedProps.calid, id: props.particular.extendedProps.id, date: moment.utc(props.particular.start).format("YYYY-MM-DD") })
                    .then(res => {
                        setCalendarsModal(false)

                        props.reset()
                    })
            } else {

                api.api.post('/api/attendGoogleSeries', { email: user.email, calid: props.particular.extendedProps.calid, id: props.particular.extendedProps.id, selectCal: selectedCalendar.value })
                    .then(res => {
                        reset()
                        setCalendarsModal(false)
                        props.reset()
                    })


            }


        } else {
           
        }
    }
    const handleDeleteSingle = () => {
        if (!props.particular.extendedProps.isGoogle) {
            if (props.mode == "home") {
                api.api.post('/api/deleteSingle', { fromSeries: props.particular.extendedProps.rrule ? true : false, event: props.particular })
                    .then((res) => props.reset())
            }
            else if (props.mode == "milieu") {
                api.api.post('/api/deleteSingleMilieuEvent', { fromSeries: props.particular.extendedProps.rrule ? true : false, event: props.particular })
                    .then((res) => props.reset())
            }

        } else {
            api.api.post("/api/deleteSingleGoogleEvent/", { old: props.particular, fromSeries: props.particular.extendedProps.rrule ? true : false, start: props.particular.start, event: props.particular, email: user.email, id: props.particular.extendedProps.id, calid: props.particular.extendedProps.calid, extended: props.particular.extendedProps })
                .then((res) => props.reset())
        }
        props.setGoogleEvents([])
    }
    const handleCommentSeries = () => {
        if (props.particular.extendedProps.isGoogle) {
            api.api.post("/api/commentGoogleSeries", { email: user.email, comment: comment, id: props.particular.extendedProps.id })
                .then(res => {
                    reset()
                    props.reset()
                })


        } else {
            api.api.post("/api/commentSeries", { email: user.email, comment: comment, id: props.particular.extendedProps.id })
                .then(res => {
                    reset()
                    props.reset()
                })
        }
    }
    const handleCommentSingle = () => {
        if (props.particular.extendedProps.isGoogle) {
            api.api.post("/api/commentGoogleSingle", { email: user.email, comment: comment, id: props.particular.extendedProps.id, date: props.particular.start })
                .then(res => {
                    reset()
                    props.reset()
                })
        } else {
            api.api.post("/api/commentSingle", { email: user.email, comment: comment, id: props.particular.extendedProps.id, date: props.particular.start })
                .then(res => {
                    reset()
                    props.reset()
                })
        }
    }
    const handleDeleteSeries = () => {
        console.log(props.cal.isGoogle)
        if (!props.particular.extendedProps.isGoogle) {
            if (props.mode == "home") {
                api.api.post('/api/deleteSeries', { event: props.particular })
                    .then((res) => props.reset())
            }
            else if (props.mode == "milieu") {
                api.api.post('/api/deleteSeriesMilieu', { event: props.particular })
                    .then((res) => props.reset())
            }
        } else {

            api.api.post("/api/deleteGoogleEvent/", { email: user.email, calid: props.particular.extendedProps.calid, id: props.particular.extendedProps.id })
                .then((res) => props.reset())

        }
        props.setGoogleEvents([])

    }
    const reset = () => {

        if (props.eventData.mId) {
            api.api.get(`/api/getMilieuById/?id=${props.eventData.mId}`)
                .then((res) => setMilieu(res.data[0].name))

        }
        api.api.get(`/api/getUserMilieuCalendars/?email=${user.email}`)
            .then(res => setCalendars(res.data))

        api.api.get(`/api/getAttendance/?email=${user.email}`)
            .then(res => setAttendance(res.data))



        if (props.particular.extendedProps.isGoogle) {
            api.api.get(`/api/getGoogleComments/?id=${props.particular.extendedProps.id}`)
                .then(res => setComments(res.data))
        } else {
            api.api.get(`/api/getComments/?id=${props.particular.extendedProps.id}`)
                .then(res => setComments(res.data))
        }

    }

    return (

        <div >
            <div>
                <div >
                    <div style={{ display: "flex", maxHeight: "300px" }}>
                        <Col >

                            {
                                props.particular.extendedProps.isPaid ?
                                    <Card text={"success" === 'light' ? 'dark' : 'white'} bg={"success"} className="mb-2">


                                        <Card.Header>
                                            {payMessage}
                                        </Card.Header>
                                        <Card.Footer>
                                            Due: {payDate}
                                        </Card.Footer>

                                    </Card> : null
                            }
                            <Card style={{ height: "300px", width: '18rem' }}>
                                <Card.Header>
                                    <Nav variant="tabs" defaultActiveKey="#first">
                                        <Nav.Item>
                                            <Nav.Link onClick={() => { setDeleteToggle(true); setEditToggle(false); setAttendToggle(false) }} >Delete</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link onClick={() => { setDeleteToggle(false); setEditToggle(true); setAttendToggle(false) }}>Edit</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link onClick={() => { setDeleteToggle(false); setEditToggle(false); setAttendToggle(true) }}>
                                                Attendance
                                            </Nav.Link>
                                        </Nav.Item>
                                    </Nav>
                                </Card.Header>




                                <Card.Header>

                                    <Nav defaultActiveKey="#first">
                                        {edittoggle ?
                                            <Nav.Item className='single' onClick={handleSingle}>
                                                <Nav.Link>
                                                    Edit Single
                                                </Nav.Link>
                                            </Nav.Item>
                                            : null}
                                        {edittoggle ?
                                            <Nav.Item className="series" onClick={handleSeries}>
                                                <Nav.Link>
                                                    Edit Series
                                                </Nav.Link>
                                            </Nav.Item>
                                            : null}
                                        {deletetoggle ?
                                            <Nav.Item className='single' onClick={handleDeleteSingle}>
                                                <Nav.Link>
                                                    Delete Single
                                                </Nav.Link>
                                            </Nav.Item> : null}

                                        {deletetoggle ?
                                            props.particular.extendedProps.rrule ?
                                                <Nav.Item className="series" onClick={handleDeleteSeries}>
                                                    <Nav.Link>
                                                        Delete Series
                                                    </Nav.Link>
                                                </Nav.Item> :
                                                <Nav.Item>
                                                    <Nav.Link disabled>
                                                        Delete Series
                                                    </Nav.Link>
                                                </Nav.Item>
                                            : null}
                                        {attendToggle ?
                                            <Nav.Item className='single' >
                                                {single == "not" ?
                                                    null : single ?
                                                        props.particular.extendedProps.isPaid ?
                                                            <OverlayTrigger
                                                                placement="right"
                                                                delay={{ show: 250, hide: 400 }}
                                                                overlay={<Tooltip  {...props}>
                                                                    {toolTipMessage}
                                                                </Tooltip>}
                                                            >
                                                                <Nav.Link onClick={handleAttendSingle}>
                                                                    Attend Single
                                                                </Nav.Link>
                                                            </OverlayTrigger>

                                                            :


                                                            <Nav.Link onClick={handleAttendSingle}>
                                                                Attend Single
                                                            </Nav.Link> : <Nav.Link onClick={handleAttendSingle}>
                                                            Stop Attending
                                                        </Nav.Link>}
                                            </Nav.Item>

                                            : null}
                                        {attendToggle ?
                                            <Nav.Item className='series' >
                                                {series ?
                                                    props.particular.extendedProps.rrule?
                                                    props.particular.extendedProps.isPaid ?
                                                        <OverlayTrigger
                                                            placement="right"
                                                            delay={{ show: 250, hide: 400 }}
                                                            overlay={<Tooltip  {...props}>
                                                                {paySeriesTip}
                                                            </Tooltip>}
                                                        >
                                                            <Nav.Link onClick={handleAttendSeries}>
                                                                Attend Series
                                                            </Nav.Link>
                                                        </OverlayTrigger>

                                                        :
                                                        <Nav.Link onClick={handleAttendSeries}>
                                                            Attend Series
                                                        </Nav.Link>
:null

                                                    :
                                                    <Nav.Link onClick={handleAttendSeries}>
                                                        Stop Attending
                                                    </Nav.Link>}
                                            </Nav.Item>
                                            : null}

                                        {attendToggle ?
                                            <Nav.Item className='series' >
                                                <Nav.Link onClick={handleInvite}>
                                                    Invite
                                                </Nav.Link>
                                            </Nav.Item>
                                            : null}
                                    </Nav>
                                </Card.Header>

                                <Card.Header>{props.eventData.mId ? <h2>{milieu}  ⋅</h2> : null}  {props.particular.extendedProps.cname}</Card.Header>
                                <Card.Body style={{ overflow: 'scroll', maxHeight: "30%" }}>
                                    <Card.Title>{props.eventData.title}</Card.Title>
                                    <Card.Text >

                                        {props.particular.extendedProps.description}
                                    </Card.Text>
                                </Card.Body>
                                <ListGroup style={{ minHeight: "10%" }} className="list-group-flush">
                                    <ListGroup.Item >{props.particular.extendedProps.duration ? moment(props.particular._instance.range.start).format("MMM Do").toString() + " ⋅ " +
                                        moment(props.particular._instance.range.start).add("7", "hours").format("h:mm a").toString()
                                        + " - " + moment(props.particular._instance.range.start).add("7", "hours")
                                            .add(props.particular.extendedProps.duration.split('-')[1], 'millisecond').format("h:mm a").toString() : null
                                    }</ListGroup.Item>
                                    <ListGroup.Item>Location</ListGroup.Item>
                                </ListGroup>

                            </Card>
                        </Col>
                        <Col style={{ flexGrow: "1" }}>
                            <Card>
                                <Card.Header>Attendants</Card.Header>
                                <Card.Body style={{ overflow: "scroll", maxHeight: '300px' }}>
                                    {attendants.filter(i => i.userevents_id == props.particular.extendedProps.id).filter(i => i.isExcept ? i.date.split(',').includes(moment.utc(props.particular.start).format("YYYY-MM-DD")) : true).map(
                                        i => <div>{i.users_email}</div>
                                    )

                                    }</Card.Body>
                            </Card>
                        </Col>
                    </div>
                    <div style={{ marginTop: "100px" }}>
                        <Card>
                            <Card.Header>Comments</Card.Header>
                            <Card.Body style={{ overflow: "scroll", maxHeight: "300px" }}>
                                {
                                    comments.filter(i => !i.isReply).filter(i => i.isExcept ? i.dates.split(',').includes(moment(props.particular.start).format("YYYY-MM-DD")) : true).map(i =>
                                        <Comment isGoogle={props.particular.extendedProps.isGoogle} userevents_id={props.particular.extendedProps.id} reset={reset} sustain={sustain} setSustain={setSustain} togglecomments={togglecomments} setTogglecomments={setTogglecomments} id={i.id} />
                                    )
                                }



                            </Card.Body>

                        </Card>
                        <div>

                            <div>
                                <Form.Control as="textarea" onChange={(e) => setComment(e.target.value)} rows={2} />
                            </div>
                            <div style={{ margin: "10px", display: "flex", justifyContent: "space-around" }}>
                                <Button onClick={handleCommentSingle}>Comment on Single</Button>
                                <Button onClick={handleCommentSeries} >Comment on Series</Button>
                            </div>
                        </div>
                    </div>
                </div>

            </div>



            <Modal style={{ top: "10%", boxSizing: "border-box", padding: "200px" }} show={calendarsModal} onHide={() => setCalendarsModal(!calendarsModal)} >
                <Modal.Header closeButton>
                    <Modal.Title>Select A Milieu Calendar</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <Select
                        value={selectedCalendar}
                        onChange={(e) => { setSelectedCalendar(e) }}
                        options={calendars.filter(i => i.role ? i.role == "writer" || i.role == "owner" : true).map(i => { return { value: i.id, label: i.name ? i.name : "untitled" } })}
                    />

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={() => handleConfirm()}>
                        Confirm
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal style={{ top: "10%", boxSizing: "border-box", padding: "200px" }} show={inviteModal} onHide={() => setInviteModal(!inviteModal)} >
                <Modal.Header closeButton>
                    <Modal.Title>Invite</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <label>Contacts</label>
                        <Select
                            value={selectedContacted}
                            onChange={(e) => { setSelectedContacted(e) }}
                            options={contacts}
                            isMulti={true}
                        />
                    </div>
                    <div>
                        <label>Uncontacted</label>
                        <TagsInput
                            value={selectedUncontacted}
                            onChange={setSelectedUncontacted}
                            name="Emails"
                            placeHolder="Enter an email"
                            separators={" "}
                        />
                    </div>

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={() => handleConfirmInvite()}>
                        Confirm
                    </Button>
                </Modal.Footer>
            </Modal>


        </div>

    )
}

