import Cal from '../components/Cal.js'
import NavBar from '../components/NavBar.js'
import axios from 'axios';
import React,{useEffect, useState} from 'react';
import { CgCommunity } from "react-icons/cg";
import { HiMiniUserGroup } from "react-icons/hi2";
import { MdGroupAdd } from "react-icons/md";
import { FaUserFriends } from "react-icons/fa";
import { BiSolidMessageAltDetail } from "react-icons/bi";
import { IoCalendarNumber } from "react-icons/io5";
import { IoIosCreate } from "react-icons/io";
import { TbCalendarPlus } from "react-icons/tb";
import "../css/home.css"
import { useApi } from '../config.js'
import { useParams } from "react-router-dom";
import { useAuth } from '../contexts/authContext/index.js';
import SideBarItem from '../components/SideBarItem.js';
import SideBar from '../components/SideBar.js'

export default function User(props) {
    const api = useApi()
    const [toggle,setToggle] = useState(false)
    var {id} = useParams();
    const { user, isAuthenticated, isLoading } = useAuth();
    useEffect(()=>{
        if(isAuthenticated){
            api.api.post('/api/signup',{email:user.email})
        }},[isLoading])
    if (isLoading) {
        return <div>Loading ...</div>;
    }

    return (

        <div className='whole'>
            {isAuthenticated && (
                <>
                    <NavBar/>

                    <h1>
                        {id}
                    </h1>
                    <div className='holder'>
                    <SideBar />

                    <div className='calendar'>
                        <Cal id={id} mode={'user'} />
                    </div>
                    </div>

                </>
            )}
        </div>
    )
}