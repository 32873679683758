

import React,{useState} from 'react'

import { useAuth } from '../contexts/authContext/index.js';
import { Link } from 'react-router-dom';

export default function SideBarItem({icon,link,name}) {
    
    
    return (
        <div className='container'>

            <div className='icon'>
            <Link to={link}>{icon}</Link></div>
            <div className='title'>{name}</div>
            
        </div>
    )
}