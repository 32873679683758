import React, { useState, useEffect } from "react"
import { useApi } from '../config'
import { useAuth } from '../contexts/authContext/index.js';
import NavBar from "../components/NavBar"
import Nav from 'react-bootstrap/Nav';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Card from 'react-bootstrap/Card';
import ListGroup from 'react-bootstrap/ListGroup';
import Form from 'react-bootstrap/Form';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Select from 'react-select'
import { useNavigate } from "react-router-dom"
import SideBar from "../components/SideBar"
import { List } from "@mui/material";
const stripe = require('stripe')('sk_test_51OLsoxHgQmd0MvYkfDfYbnTXKPezdKht0Y10j7LwgYXd4mFXSgU6ygSxL26VgzY0dAgAuClaFtD0iUXL7n7mxXVN00xWPmeoFI');
export default function Settings() {

    var nav = useNavigate();
    const { user } = useAuth()
    var api = useApi()
    const [firstName, setFirstName] = useState("")
    const [lastName, setLastName] = useState("")
    const [userName, setUserName] = useState("")
    const [paymentMethods, setPaymentMethods] = useState([])
    const [paymentOptions, setPaymentOptions] = useState([])
    const [def, setDef] = useState({})
    useEffect(() => {
        api.api.get(`/api/getPaymentMethods?email=${user.email}`)
            .then(res => setPaymentMethods(res.data))
        api.api.get(`/api/getDetails/?email=${user.email}`)
            .then(res => {
                setFirstName(res.data.first_name)
                setLastName(res.data.last_name)
                setUserName(res.data.username)
            })
    }, [])
    useEffect(() => {
        getDetails()

    }, [paymentMethods])
    useEffect(() => {
        api.api.post(`/api/setDefCard`, { email: user.email, method: def.value })
    }, [def])
    useEffect(() => {
        if (paymentOptions.length > 0) {
            if (paymentMethods.filter(i => i.isDef == 1).length > 0) {
                setDef(paymentOptions.filter(j => j.value == paymentMethods.filter(i => i.isDef == 1)[0].paymentMethod)[0])
            } else {
                setDef(paymentOptions[0])
            }
        }


    }, [paymentOptions])

    const getDetails = async () => {
        var l = []
        for (var i in paymentMethods) {
            const paymentMethod = await stripe.paymentMethods.retrieve(
                paymentMethods[i].paymentMethod
            )
            l.push({ value: paymentMethod.id, label: `⋅ ⋅ ⋅ ⋅  ⋅ ⋅ ⋅ ⋅  ⋅ ⋅ ⋅ ⋅  ${paymentMethod.card.last4}` })
        }
        console.log(l)
        setPaymentOptions(l)
    }
    const handleConfirm = () => {
        api.api.post("/api/setDetails", { firstName: firstName, lastName: lastName, userName: userName, email: user.email })
            .then(res => {
                console.log("RESET")
                reset()
            })
    }
    const reset = () => {

        api.api.get(`/api/getDetails/?email=${user.email}`)
            .then(res => {
                setFirstName(res.data.first_name)
                setLastName(res.data.last_name)
                setUserName(res.data.username)
            })

    }

    const handleAdd = async () => {

        await fetch("/.netlify/functions/stripe-future", {
            method: "POST",
            body: JSON.stringify({
                email: user.email,
            })
        }).then(async res => {
            const result = await res.json()
            window.location.replace(result.url)
        })

    }

    const associate = () => {
        fetch("/.netlify/functions/settleAccounts", {
            method: "POST",
            body: JSON.stringify({
                email:user.email
            })
        }).then(async res=>{
                const result = await res.json()
                if(result.url!==null){
                window.open(result.url)
            }
                
            api.api.post('/api/addAccount',{email:user.email,acc:result.acc})
                
            })
    }
    return (
        <>
            <NavBar />
            <SideBar />
            <div>
                <h1>Settings</h1>
                <Card style={{ margin: "auto", width: "500px" }}>
                    <Card.Header>
                        Identification
                    </Card.Header>
                    <ListGroup>
                        <ListGroup.Item><Form.Control value={firstName} placeholder="First Name" onChange={(e) => setFirstName(e.target.value)} /></ListGroup.Item>
                        <ListGroup.Item><Form.Control value={lastName} placeholder="Last Name" onChange={(e) => setLastName(e.target.value)} /></ListGroup.Item>
                        <ListGroup.Item><Form.Control value={userName} placeholder="Username" onChange={(e) => setUserName(e.target.value)} /></ListGroup.Item>


                    </ListGroup>
                    <Card.Footer>
                        <Button variant="primary" onClick={handleConfirm}>Confirm</Button>
                    </Card.Footer>
                </Card>
                <Card style={{ margin: "auto", marginTop: "10px", width: "500px" }}>
                    <Card.Header>
                        Payment
                    </Card.Header>
                    <Card.Body>

                        <ListGroup>



                            <ListGroup.Item>
                                <Form.Label>Select Default Card</Form.Label>
                                <Select
                                    isDisabled={paymentOptions.length == 0}
                                    options={paymentOptions}
                                    value={def}
                                    onChange={setDef}
                                />
                            </ListGroup.Item>
                            <ListGroup.Item>
                                <Button style={{margin:"10px"}} onClick={handleAdd} variant="success">Add a card</Button>
                                <OverlayTrigger
                                    placement="right"
                                    delay={{ show: 250, hide: 400 }}
                                    overlay={<Tooltip  >
                                        This will allow you to receive funds and set up paid events. 
                                    </Tooltip>}
                                >
                                    <Button style={{margin:"10px"}} onClick={associate} variant="primary">Associate with Stripe</Button>
                                </OverlayTrigger>

                            </ListGroup.Item>
                        </ListGroup>
                    </Card.Body>
                </Card>
            </div>


        </>
    )
}