
import React, { useEffect, useState } from 'react';
import rrulePlugin from '@fullcalendar/rrule'
import '../../css/modal.css'
import 'react-datetime-picker/dist/DateTimePicker.css';
import 'react-calendar/dist/Calendar.css';
import 'react-clock/dist/Clock.css';
import DatePicker from "react-multi-date-picker";
import { DayPicker } from "react-day-picker";
import "react-day-picker/style.css";
import DateTimePicker from 'react-datetime-picker';
import Select from 'react-select'
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import ListGroup from 'react-bootstrap/ListGroup';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Collapse from 'react-bootstrap/Collapse';
import Stack from 'react-bootstrap/Stack';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Nav from 'react-bootstrap/Nav';
import { datetime, Frequency, RRule, RRuleSet, rrulestr } from 'rrule'
import Switch from '@mui/material/Switch';
import * as moment from 'moment-timezone'
import { useApi } from '../../config'
import { MultiSelect } from "react-multi-select-component";
import { useAuth } from '../../contexts/authContext/index.js';
import momentTimezonePlugin from '@fullcalendar/moment-timezone';
const options = [
    { value: 'never', label: 'Never' },
    { value: 0, label: 'Every Day' },
    { value: 1, label: 'Every Week' },
    { value: 2, label: 'Every Month' },
    { value: 3, label: 'Every Year' },
    { value: 'custom', label: 'Custom' }
]

const custOptions = [
    { value: 'daily', label: 'Daily' },
    { value: 'weekly', label: 'Weekly' },
    { value: 'monthly', label: 'Monthly' },

]

const dayOptions = [
    { value: 6, label: 'Sunday' },
    { value: 0, label: 'Monday' },
    { value: 1, label: 'Tuesday' },
    { value: 2, label: 'Wednesday' },
    { value: 3, label: 'Thursday' },
    { value: 4, label: 'Friday' },
    { value: 5, label: 'Saturday' },
    { value: 'd', label: 'day' },
    { value: 'w', label: 'weekday' },
    { value: 'we', label: 'weekend day' },

]

const onthe = [
    { value: 1, label: 'first' },
    { value: 2, label: 'second' },
    { value: 3, label: 'third' },
    { value: 4, label: 'fourth' },
    { value: 5, label: 'fifth' },
    { value: -1, label: 'last' },
]

const timeModes = [
    { value: 0, label: "minutes" },
    { value: 1, label: "hours" },
    { value: 2, label: "days" },
]
export default function EventSetup(props) {
    const { user } = useAuth();
    var api = useApi();
    const [tags, setTags] = useState([])
    const [selectedTags, setSelectedTags] = useState([])
    const [title, setTitle] = useState("")
    const [allDay, setAllDay] = useState(0)
    const [multiDate, setMultiDate] = useState([])
    //const [modal, setModal] = useState(false);
    const [value, onChange] = useState(moment().format())
    const [valueEnd, onChangeEnd] = useState(moment().add(1, 'hour').format())
    const [select, setSelect] = useState('never');
    const [cSelect, setCSelect] = useState('daily');
    const [cSelect2, setCSelect2] = useState([]);
    const [every, setEvery] = useState(1)
    const [eachOr, setEachOr] = useState(false);
    const [onThe, setOnThe] = useState("1")
    const [endRepeat, setEndRepeat] = useState()
    const [monthly, setMonthly] = useState("")
    const [until, setUntil] = useState(false)
    const [after, setAfter] = useState(0)
    const [forever, setForever] = useState(false)
    const [desc, setDesc] = useState("")
    const [price, setPrice] = useState(0)
    const [timeBefore, setTimeBefore] = useState(0)
    const [upFront, setUpFront] = useState(true)
    const [totalCost, setTotalCost] = useState(0)
    const [tMode, setTMode] = useState(2)
    const [fixed,setFixed] = useState(false)
    const [paid,setPaid] = useState(false)

    useEffect(() => {
        console.log("FOREVER", cSelect2)
    }, [cSelect2])
    useEffect(() => {
        if(props.event.extendedProps){
        setPrice(props.event.extendedProps.price)
        setTimeBefore(props.event.extendedProps.periodBefore)
        setUpFront(props.event.extendedProps.upFront)
        setTotalCost(props.event.extendedProps.price)
        setTMode(timeModes.filter(i=>i.value == props.event.extendedProps.unitBefore )[0])
        setDesc(props.event.extendedProps ? props.event.extendedProps.description : null)
    }
        if (props.series == "series") {
            setAllDay(props.event.allDay)
            setTitle(props.event.title)
            onChange(props.event.extendedProps ? moment(props.event.extendedProps.start).format() : moment(props.event.start).format())
            //setSelectedTags(props.event.extendedProps?props.event.extendedProps.tags? props.event.extendedProps.tags.map(i=>{return{value:i.tag_id,label:i.name}}):[]:null)

            props.event.extendedProps ?
                props.event.extendedProps.duration ? onChangeEnd(moment(props.event.extendedProps.start ? props.event.extendedProps.start : props.event.start).add(props.event.extendedProps.duration.split('-')[1], "milliseconds").format())
                    : onChangeEnd(props.event.extendedProps.start ? props.event.extendedProps.start : props.event.start)
                : onChangeEnd(props.event.start)
            if (props.event.extendedProps) {
                if (props.event.extendedProps.rrule) {
                    var r = props.event.extendedProps.rrule
                    var rule = RRule.fromString(r).options
                    console.log("RULE", rule)
                    setEvery(rule.interval)
                    if (rule.count) {
                        setAfter(rule.count)
                        setForever(false)
                        setUntil(false)
                    }
                    else if (rule.until) {
                        console.log("UNTIL")
                        setEndRepeat(new Date(rule.until))
                        setUntil(true)
                        setForever(false)
                    } else {
                        setForever(true)
                    }
                    switch (`${rule.freq}`) {

                        case "0":
                            setSelect({ value: 3, label: 'Every Year' });
                            break;
                        case "1":
                            setSelect({ value: 2, label: 'Every Month' });
                            break;
                        case "2":
                            setSelect({ value: 1, label: 'Every Week' });
                            break;
                        case "3":
                            setSelect({ value: 0, label: 'Every Day' });
                            break;
                    }
                    if ((rule.freq !== 0 && rule.bymonthday.length > 1) || (rule.freq !== 0 && rule.bymonthday.length == 1 && rule.bymonthday[0] !== moment(props.particular.extendedProps.start).date())) {

                        setSelect({ value: 'custom', label: 'Custom' })
                        setCSelect({ value: 'monthly', label: 'Monthly' })
                        setEachOr(true)
                        setMultiDate(rule.bymonthday.map(i => new Date(moment(props.particular.start).year(), moment(props.particular.start).month(), i)))
                    }
                    if (rule.byweekday && rule.freq == 1) {
                        setSelect({ value: 'custom', label: 'Custom' })
                        setCSelect({ value: 'monthly', label: 'Monthly' })
                        setEachOr(false)
                        switch (`${rule.bysetpos ? rule.bysetpos[0] : null}`) {

                            case "1":
                                setOnThe({ value: 1, label: 'first' });
                                break;
                            case "2":
                                setOnThe({ value: 2, label: 'second' });
                                break;
                            case "3":
                                setOnThe({ value: 3, label: 'third' });
                                break;
                            case "4":
                                setOnThe({ value: 4, label: 'fourth' });
                                break;
                            case "5":
                                setOnThe({ value: 5, label: 'fifth' });
                                break;
                            case "-1":
                                setOnThe({ value: -1, label: 'last' });
                                break;
                        }

                        if (rule.byweekday.length == 5) {
                            setMonthly({ value: 'w', label: 'weekday' })
                        } else if (rule.byweekday.length == 2) {
                            setMonthly({ value: 'we', label: 'weekend day' })
                        }
                        else if (rule.byweekday.length == 7) {
                            setMonthly({ value: 'd', label: 'day' })
                        }
                        else if (rule.byweekday.length == 1) {


                            switch (rule.byweekday[0]) {
                                case 0:
                                    setMonthly({ value: 0, label: 'Monday' });
                                    break;
                                case 1:
                                    setMonthly({ value: 1, label: 'Tuesday' });
                                    break;
                                case 2:
                                    setMonthly({ value: 2, label: 'Wednesday' });
                                    break;
                                case 3:
                                    setMonthly({ value: 3, label: 'Thursday' });
                                    break;
                                case 4:
                                    setMonthly({ value: 4, label: 'Friday' });
                                    break;
                                case 5:
                                    setMonthly({ value: 5, label: 'Saturday' });
                                    break;
                                case 6:
                                    setMonthly({ value: 6, label: 'Sunday' });
                                    break;
                            }

                        }

                    }
                    if (rule.freq == 0 && rule.interval > 1) {
                        setSelect({ value: 'custom', label: 'Custom' })
                        setCSelect({ value: 'yearly', label: 'Yearly' })
                    }
                    if (rule.freq == 2 && rule.byweekday.length > 1) {
                        setSelect({ value: 'custom', label: 'Custom' })
                        setCSelect({ value: 'weekly', label: 'Weekly' })
                        var adding = []
                        for (var i in rule.byweekday) {
                            switch (`${rule.byweekday[i]}`) {

                                case "1":
                                    adding.push({ value: 1, label: 'Tuesday' });
                                    break;
                                case "2":
                                    adding.push({ value: 2, label: 'Wednesday' });
                                    break;
                                case "3":
                                    adding.push({ value: 3, label: 'Thursday' });
                                    break;
                                case "4":
                                    adding.push({ value: 4, label: 'Friday' });
                                    break;
                                case "5":
                                    adding.push({ value: 5, label: 'Saturday' });
                                    break;
                                case "0":
                                    adding.push({ value: 0, label: 'Monday' });
                                    break;
                                case "6":
                                    adding.push({ value: 6, label: 'Sunday' });
                                    break;
                            }

                        }

                        setCSelect2(adding)
                    }







                }

            }
        } else {
            setAllDay(props.event.allDay)
            setTitle(props.event.title)
            onChange(props.event.start)
            onChangeEnd(moment(props.event.start).add(props.event.extendedProps.duration.split('-')[1], "milliseconds").format())
        }

        api.api.get(`/api/getUserTags/?email=${user.email}`)
            .then((res) => setTags(res.data.map(i => { return { label: `${i.prioritization} | ${i.name}`, value: i.tags_id } })))
    }
        , [props])

    function handleSelect(event) {
        console.log(event.target.value)
        setSelect(event.target.value)
    }
    function custHandleSelect(event) {
        setCSelect(event.target.value)
        console.log(event.target.value)
    }
    function custHandleSelect2(value) {
        //setCSelect2(event)
    }
    function onTheHandleSelect(event) {
        setOnThe(event.target.value)
    }
    function monthlyHandleSelect(event) {
        setMonthly(event.target.value)
    }










    ///////////UPDATE EVENT////////////
    function CreateEvent(re, mode, event) {
        var startDate;
        var endDate;
        var startTime;
        var moveEvent = false;
        var endTime;
        startDate = moment(value).format().split("T")[0]
        endDate = moment(valueEnd).format().split("T")[0]
        startTime = moment(value).format().split("T")[1]
        endTime = moment(valueEnd).format().split("T")[1]

        var rule
        var day;
        var freq;
        var end;


        var duration = moment.duration(moment(value).diff(moment(valueEnd)))
        var dur = '00:00:00.' + String(duration._milliseconds)


        if (props.series == "series") {
            if (select.value == "never") {
                console.log("TEST")

            } else if (select.value != 'custom') {



                switch (`${select.value}`) {
                    case "0":
                        freq = RRule.DAILY;
                        break;
                    case "1":
                        freq = RRule.WEEKLY
                        break;
                    case "2":
                        freq = RRule.MONTHLY
                        break;
                    case "3":
                        freq = RRule.YEARLY
                        break;
                }

                //noncustom

                if (forever) {
                    rule = new RRule({
                        freq: freq,
                        dtstart: new Date(moment(value)),
                    })

                } else {

                    if (until) {
                        rule = new RRule({
                            freq: freq,
                            dtstart: new Date(moment(value)),
                            until: new Date(moment(endRepeat)),
                        })

                    } if (!until) {
                        rule = new RRule({
                            freq: freq,
                            dtstart: new Date(moment(value)),
                            count: after,
                        })

                    }
                }

            } else {



                if (cSelect.value == "daily") {


                    if (forever) {
                        rule = new RRule({
                            freq: RRule.DAILY,
                            dtstart: new Date(moment(value)),
                            interval: every,
                        })
                    } else {

                        if (until) {
                            rule = new RRule({
                                freq: RRule.DAILY,
                                dtstart: new Date(moment(value)),
                                interval: every,
                                until: new Date(moment(endRepeat)),
                            })
                        } if (!until) {
                            rule = new RRule({
                                freq: RRule.DAILY,
                                dtstart: new Date(moment(value)),
                                interval: every,
                                count: after,
                            })
                        }
                    }

                }
                if (cSelect.value == "weekly") {


                    if (forever) {
                        rule = new RRule({
                            freq: RRule.WEEKLY,
                            dtstart: new Date(moment(value)),
                            interval: every,
                            byweekday: cSelect2.map(i => i.value),
                        })
                    } else {
                        if (until) {

                            rule = new RRule({
                                freq: RRule.WEEKLY,
                                dtstart: new Date(moment(value)),
                                interval: every,
                                byweekday: cSelect2.map(i => i.value),
                                until: new Date(moment(endRepeat)),
                            })

                        }
                        if (!until) {
                            rule = new RRule({
                                freq: RRule.WEEKLY,
                                dtstart: new Date(moment(value)),
                                interval: every,
                                byweekday: cSelect2.map(i => i.value),
                                count: after,
                            })
                        }
                    }


                } else if (cSelect.value == 'monthly') {

                    if (eachOr) {

                        if (forever) {
                            rule = new RRule({
                                freq: RRule.MONTHLY,
                                dtstart: new Date(moment(value)),
                                interval: every,
                                bymonthday: multiDate.map(o => (o.day))
                            })
                        } else {
                            if (until) {
                                rule = new RRule({
                                    freq: RRule.MONTHLY,
                                    dtstart: new Date(moment(value)),
                                    interval: every,
                                    until: new Date(moment(endRepeat)),
                                    bymonthday: multiDate.map(o => (o.day))
                                })
                            } if (!until) {
                                rule = new RRule({
                                    freq: RRule.MONTHLY,
                                    dtstart: new Date(moment(value)),
                                    interval: every,
                                    count: after,
                                    bymonthday: multiDate.map(o => (o.day))
                                })
                            }
                        }



                    } else {


                        if (monthly == 'd') {
                            if (forever) {
                                rule = new RRule({
                                    freq: RRule.MONTHLY,
                                    dtstart: new Date(moment(value)),
                                    interval: every,
                                    byweekday: [0, 1, 2, 3, 4, 5, 6],
                                    bysetpos: onThe,
                                })
                            } else {
                                if (until) {
                                    rule = new RRule({
                                        freq: RRule.MONTHLY,
                                        dtstart: new Date(moment(value)),
                                        interval: every,
                                        until: new Date(moment(endRepeat)),
                                        byweekday: [0, 1, 2, 3, 4, 5, 6],
                                        bysetpos: onThe,
                                    })
                                } if (!until) {
                                    rule = new RRule({
                                        freq: RRule.MONTHLY,
                                        dtstart: new Date(moment(value)),
                                        interval: every,
                                        count: after,
                                        byweekday: [0, 1, 2, 3, 4, 5, 6],
                                        bysetpos: onThe,
                                    })
                                }
                            }




                        } else if (monthly == 'w') {

                            if (forever) {
                                rule = new RRule({
                                    freq: RRule.MONTHLY,
                                    dtstart: new Date(moment(value)),
                                    interval: every,
                                    byweekday: [0, 1, 2, 3, 4],
                                    bysetpos: onThe,
                                })
                            } else {
                                if (until) {
                                    rule = new RRule({
                                        freq: RRule.MONTHLY,
                                        dtstart: new Date(moment(value)),
                                        interval: every,
                                        until: new Date(moment(endRepeat)),
                                        byweekday: [0, 1, 2, 3, 4],
                                        bysetpos: onThe,
                                    })
                                } if (!until) {
                                    rule = new RRule({
                                        freq: RRule.MONTHLY,
                                        dtstart: new Date(moment(value)),
                                        interval: every,
                                        count: after,
                                        byweekday: [0, 1, 2, 3, 4],
                                        bysetpos: onThe,
                                    })
                                }
                            }



                        } else if (monthly == 'we') {
                            if (forever) {
                                rule = new RRule({
                                    freq: RRule.MONTHLY,
                                    dtstart: new Date(moment(value)),
                                    interval: every,
                                    byweekday: [5, 6],
                                    bysetpos: onThe,
                                })
                            } else {
                                if (until) {
                                    rule = new RRule({
                                        freq: RRule.MONTHLY,
                                        dtstart: new Date(moment(value)),
                                        interval: every,
                                        until: new Date(moment(endRepeat)),
                                        byweekday: [5, 6],
                                        bysetpos: onThe,
                                    })
                                } if (!until) {
                                    rule = new RRule({
                                        freq: RRule.MONTHLY,
                                        dtstart: new Date(moment(value)),
                                        interval: every,
                                        count: after,
                                        byweekday: [5, 6],
                                        bysetpos: onThe,
                                    })
                                }
                            }



                        }
                        else {
                            if (forever) {
                                rule = new RRule({
                                    freq: RRule.MONTHLY,
                                    dtstart: new Date(moment(value)),
                                    interval: every,
                                    byweekday: monthly,
                                    bysetpos: onThe
                                })
                            } else {
                                if (until) {
                                    rule = new RRule({
                                        freq: RRule.MONTHLY,
                                        dtstart: new Date(moment(value)),
                                        interval: every,
                                        until: new Date(moment(endRepeat)),
                                        byweekday: monthly,
                                        bysetpos: onThe
                                    })
                                } if (!until) {
                                    rule = new RRule({
                                        freq: RRule.MONTHLY,
                                        dtstart: new Date(moment(value)),
                                        interval: every,
                                        count: after,
                                        byweekday: monthly,
                                        bysetpos: onThe
                                    })
                                }
                            }



                        }

                    }
                }
            }

        }
        if (!props.event.extendedProps.isGoogle) {
            if (props.series == "series") {
                console.log("EDITING SERIES")

                if (props.pos == "home") {
                    console.log("TMODE",tMode.value)
                    api.api.post('/api/editUserSeries', { isPaid: paid, splitPaid: !fixed, periodBefore: timeBefore, unitBefore: tMode.value, upFront: upFront, price: fixed ? price : totalCost, description: desc, tags: selectedTags ? selectedTags.map(i => i.value) : null, id: props.event.extendedProps.id, calid: props.event.extendedProps.calid, rrule: rule ? rule.toString() : null, start: moment(value).format(), title: title, allday: allDay, duration: dur })
                        .then((res) => { props.reset() })

                } else if (props.pos == "milieu") {
                    api.api.post('/api/editMilieuSeries', { isPaid: paid, splitPaid: !fixed, periodBefore: timeBefore, unitBefore: tMode.value, upFront: upFront, price: fixed ? price : totalCost, description: desc, calid: props.event.extendedProps.calid, tags: selectedTags ? selectedTags.map(i => i.value) : null, id: props.event.extendedProps.id, rrule: rule ? rule.toString() : null, start: moment(value).format(), title: title, allday: allDay, duration: dur })
                        .then((res) => { props.reset() })
                }

            }
            else if (props.series == "single") {

                if (props.pos == "home") {
                    api.api.post('/api/editSingle', { isPaid: paid, splitPaid: !fixed, periodBefore: timeBefore, unitBefore: tMode.value, upFront: upFront, price: fixed ? price : totalCost, fromSingle: props.event.extendedProps.rrule ? false : true, tags: selectedTags ? selectedTags.map(i => i.value) : null, id: props.event.extendedProps.id, calid: props.event.extendedProps.calid, start: moment(value).format(), title: title, allday: allDay, duration: dur, old: props.event })
                        .then((res) => { props.reset() })
                } else if (props.pos == "milieu") {
                    api.api.post('/api/editSingleMilieu', { isPaid: paid, splitPaid: !fixed, periodBefore: timeBefore, unitBefore: tMode.value, upFront: upFront, price: fixed ? price : totalCost, tags: selectedTags ? selectedTags.map(i => i.value) : null, fromSingle: props.event.extendedProps.rrule ? false : true, calid: props.event.extendedProps.calid, id: props.event.extendedProps.id, rrule: rule ? rule.toString() : null, start: moment(value).format(), title: title, allday: allDay, duration: dur, old: props.event })
                        .then((res) => { props.reset() })
                }
            }
        } else {

            console.log("PROPS:EVENT", props.event)
            if (props.series == "single") {
                console.log("SINGLE")
                api.api.post('/api/editSingleGoogleEvent', { editingMode: props.series, offset: props.eventData.offset, start: moment(value).format(), title: title, allday: allDay, duration: dur, event: props.event, tags: selectedTags ? selectedTags : null, exclusions: props.event.extendedProps.exdate, email: user.email, fromSingle: props.event.extendedProps.rrule ? false : true, id: props.event.extendedProps.id, rrule: props.series == "single" ? null : rule.toString(), calid: props.event.extendedProps.calid, old: props.event })
                    .then((res) => {
                        props.reset()
                        props.setGoogleEvents([])
                    })
            } else if (props.series == "series") {
                console.log("SERIES")
                api.api.post('/api/editSeriesGoogleEvent', { editingMode: props.series, offset: props.eventData.offset, start: moment(value).format(), title: title, allday: allDay, duration: dur, event: props.event, tags: selectedTags ? selectedTags : null, exclusions: props.event.extendedProps.exdate, email: user.email, fromSingle: props.event.extendedProps.rrule ? false : true, id: props.event.extendedProps.id, rrule: props.series == "single" ? null : rule.toString(), calid: props.event.extendedProps.calid, old: props.event })
                    .then((res) => {
                        props.reset()
                        props.setGoogleEvents([])
                    })

            }
        }
    }


    return (
        <>
            {/* <button
                onClick={toggleModal}
                className="btn-modal">
                Create Event
            </button>
 */}


            <Modal style={{ paddingTop: "400px" }} show={props.modal} onHide={() => props.setEdit(!props.edit)}>
                <Modal.Header closeButton>
                    <Modal.Title>Edit Event</Modal.Title>
                </Modal.Header>
                <Modal.Body>


                    <Card>
                        <ListGroup variant="flush">
                            <ListGroup.Item>
                                <Form.Control value={title} placeholder='Title' onChange={(v) => { setTitle(v.target.value) }} />
                            </ListGroup.Item>

                            <ListGroup.Item>
                                <Form.Control as="textarea" placeholder='Description' rows="4" cols="21" value={desc} onChange={(v) => { setDesc(v.target.value) }} />
                            </ListGroup.Item>


                            <ListGroup.Item>
                                <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                                    <div>
                                        <div>
                                            All-day
                                        </div>
                                        <div>
                                            <Switch value={allDay} onChange={() => setAllDay(!allDay)} />
                                        </div>
                                    </div>
                                    <div >
                                        <div>
                                            <label>Start</label>
                                        </div>
                                        <div>
                                            <DateTimePicker onChange={(e) => { onChange(moment(e).format()) }} value={value} />
                                        </div>
                                        <div>
                                            <label>End</label>
                                        </div>
                                        <div>
                                            <DateTimePicker onChange={(e) => { onChangeEnd(moment(e).format()) }} value={valueEnd} />
                                        </div>
                                    </div>
                                </div>
                            </ListGroup.Item>



                        </ListGroup>
                    </Card>
                    {props.series == "series" ?
                        <Card>
                            <ListGroup>
                                <ListGroup.Item>
                                    <label>Repeat</label>
                                    <Select value={select} onChange={(e) => { console.log(e.value); setSelect(e) }}
                                        options={options}
                                    />
                                </ListGroup.Item>
                                <ListGroup.Item>

                                    <label>Forever</label>
                                    <Switch checked={forever} onClick={() => setForever(!forever)} />
                                </ListGroup.Item>
                                {select != "never" ? !forever ?
                                    <ListGroup.Item>
                                        <label>End Repeat</label>
                                        <div style={{ display: "flex" }}>
                                            <div style={{ padding: "10px" }}>
                                                <Form.Check

                                                    onClick={() => setUntil(!until)}
                                                    checked={!until}
                                                />
                                            </div>
                                            <div style={{ display: "flex", justifyContent: "space-around", alignItems: "cemter", padding: "10px" }}>
                                                <div style={{ marginRight: "10px", textAlign: "center" }}>
                                                    After
                                                </div>
                                                <Form.Control
                                                    style={{ width: `${(`${after}`).length * 50}px`, height: "30px", WebkitAppearance: "none" }}
                                                    disabled={until}
                                                    value={after} onChange={(o) => { console.log(o.target.value); setAfter(o.target.value) }}
                                                    type='number'
                                                />
                                                <div style={{ marginLeft: "10px" }}>
                                                    occurrences
                                                </div>
                                            </div>

                                        </div>
                                        <div style={{ display: "flex" }}>
                                            <div style={{ padding: "10px" }}>
                                                <Form.Check
                                                    onClick={() => setUntil(!until)}
                                                    checked={until}
                                                />
                                            </div>
                                            <div style={{ display: "flex", justifyContent: "space-around", alignItems: "cemter", padding: "10px" }}>
                                                <div style={{ marginRight: "10px", textAlign: "center" }}>
                                                    Until
                                                </div>

                                                <DatePicker disabled={!until} value={endRepeat} onChange={setEndRepeat} />

                                            </div>
                                        </div>
                                    </ListGroup.Item>
                                    : null
                                    :
                                    <div></div>
                                }
                            </ListGroup>
                        </Card> : null}

                    {select.value == "custom" ?
                        <Card>
                            <ListGroup>
                                <ListGroup.Item>
                                    <div>Frequency

                                        <Select value={cSelect} options={custOptions} onChange={setCSelect} />

                                        {cSelect.value == "weekly" ?
                                            <Select
                                                isMulti={true}
                                                options={dayOptions}
                                                value={cSelect2}
                                                onChange={setCSelect2}
                                                labelledBy="Select"
                                            />
                                            :
                                            cSelect.value == "monthly" ?
                                                <div>
                                                    <Switch value={eachOr} onChange={() => setEachOr(!eachOr)} />
                                                    {eachOr ?
                                                        <>
                                                            <div>Each...</div>
                                                            <DatePicker multiple={true} value={multiDate} onChange={(dates) => { setMultiDate(dates) }} />
                                                        </>
                                                        :
                                                        <>
                                                            <div>on the...{monthly.label}</div>

                                                            <Select isMulti={false} options={onthe} onChange={setOnThe} value={onThe} />
                                                            <Select options={dayOptions} onChange={setMonthly} value={monthly} />



                                                        </>
                                                    }
                                                </div>
                                                :
                                                cSelect.value == "yearly" ?
                                                    <div>Yearly</div>
                                                    :
                                                    <div></div>
                                        }
                                    </div>
                                </ListGroup.Item>
                                <ListGroup.Item>
                                    <div>Every <input value={every} onChange={(o) => { setEvery(o.target.value) }} type='number' /></div>
                                </ListGroup.Item>
                            </ListGroup>
                        </Card>
                        : null}

<Card>
                                <Card.Header>
                                    <div style={{ display: "flex" }}>
                                        <div className="p-2" style={{ display: "flex" }}>
                                            <Form.Check
                                                type='switch'
                                                checked={paid}
                                                onChange={() => setPaid(!paid)}
                                            /> </div>
                                        <label className="p-2" style={{ marginLeft: "10px" }}>Paid Entry</label>
                                    </div>
                                </Card.Header>

                                <Collapse in={paid} dimension="height">

                                    <Nav justify variant="tabs" defaultActiveKey="/home">
                                        <Nav.Item>
                                            <Nav.Link onClick={() => setFixed(true)} >Fixed</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link onClick={() => setFixed(false)} >Split</Nav.Link>
                                        </Nav.Item>
                                    </Nav>
                                </Collapse>

                                <Card.Body>
                                    <Collapse in={fixed && paid} dimension="height">
                                        <div id="example-collapse-text">
                                            <Card body style={{ width: '400px' }}>
                                                <ListGroup>
                                                    <ListGroup.Item>
                                                        <Form.Label>
                                                            Entry Fee in dollars
                                                        </Form.Label>

                                                        <Form.Control
                                                            type="number"
                                                            placeholder="$"
                                                            min={0}
                                                            value={price}
                                                            onChange={(e) => setPrice(e.target.value < 0 ? 0 : e.target.value)}
                                                        />

                                                    </ListGroup.Item>
                                                    <ListGroup.Item>

                                                        <div >
                                                            {select=="never"?
                                                            <div>
                                                            <div style={{ display: "flex", padding: "20px" }}>
                                                                <Form.Check
                                                                    style={{ marginRight: "10px" }}
                                                                    checked={upFront}
                                                                    onClick={() => setUpFront(!upFront)}
                                                                />
                                                                <Form.Label>Charges will be made at the instant of attendance confirmation</Form.Label>
                                                            </div>

                                                            <div style={{ display: "flex", padding: "20px" }}>
                                                                <Form.Check
                                                                    style={{ marginRight: "10px" }}
                                                                    checked={!upFront }
                                                                    onClick={() => setUpFront(!upFront)}
                                                                />
                                                            </div>
                                                            </div>
                                                            :null
}
                                                                <Form.Label>Charges will be made at the determined amount of time before the event</Form.Label>
                                                            
                                                            <div style={{ padding: "20px" }} >

                                                                
                                                                    <div style={{ display: 'flex' }}>
                                                                        <Form.Control
                                                                            disabled={upFront}
                                                                            style={{ width: "100px" }}
                                                                            type="number"
                                                                            placeholder="Enter a period"
                                                                            min={0}
                                                                            value={timeBefore}
                                                                            onChange={(e) => setTimeBefore(e.target.value < 0 ? 0 : e.target.value)}
                                                                        />
                                                                        <Select
                                                                            isDisabled={upFront}
                                                                            options={timeModes}
                                                                            onChange={(e)=>{setTMode(e);console.log(e)}}
                                                                            value={tMode}
                                                                        />
                                                                    </div>
                                                                
                                                            </div>
                                                        </div>
                                                    </ListGroup.Item>
                                                </ListGroup>
                                            </Card>
                                        </div>
                                    </Collapse>
                                    <Collapse in={!fixed && paid} dimension="height">
                                        <div id="example-collapse-text">
                                            <Card body style={{ width: '400px' }}>
                                                <Form.Label>
                                                    Total cost in dollars to be split amongst all attendants
                                                </Form.Label>

                                                <Form.Control
                                                    type="number"
                                                    placeholder="$"
                                                    min={0}
                                                    value={totalCost}
                                                    onChange={(e) => setTotalCost(e.target.value < 0 ? 0 : e.target.value)}
                                                />
                                                <Form.Label>Amount of time preceeding an event, at which point, charges will be made.</Form.Label>
                                                <div style={{ display: 'flex' }}>
                                                    <Form.Control
                                                        style={{ width: "100px" }}
                                                        type="number"
                                                        placeholder="Enter a period"
                                                        min={0}
                                                        value={timeBefore}
                                                        onChange={(e) => setTimeBefore(e.target.value < 0 ? 0 : e.target.value)}
                                                    />
                                                    <Select

                                                        options={timeModes}
                                                        onChange={(e)=>{setTMode(e);console.log(e)}}
                                                        value={tMode}
                                                    />

                                                </div>
                                            </Card>
                                        </div>
                                    </Collapse>
                                </Card.Body>
                            </Card>







                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => props.setEdit(!props.edit)}>
                        Close
                    </Button>
                    {select.value == "never" ?
                        <Button variant="primary" onClick={() => { CreateEvent(false, props.pos, props.event) }}>
                            Add
                        </Button >
                        :
                        <Button variant="primary" onClick={() => { CreateEvent(true, props.pos, props.event) }}>
                            Add
                        </Button >
                    }
                </Modal.Footer>
            </Modal >


            {
                false && (

                    <div className="modal">
                        <div className='overlay'>
                        </div>
                        <div className='modal-content'>
                            {props.cal.isHome ? "true" : "false"}
                            <h2>
                                Edit Event
                            </h2>
                            <div>
                                <div><Form.Control value={title} placeholder='Title' onChange={(v) => { setTitle(v.target.value) }} /></div>
                                <div><Form.Control as="textarea" placeholder='Description' rows="4" cols="21" value={desc} onChange={(v) => { setDesc(v.target.value) }} /></div>
                                <div>All-day <Switch value={allDay} onChange={() => setAllDay(!allDay)} /></div>
                                <div>Starts <DateTimePicker onChange={(e) => { onChange(moment(e).format()) }} value={value} /></div>
                                <div>Ends <DateTimePicker onChange={(e) => { onChangeEnd(moment(e).format()) }} value={valueEnd} /> </div>
                            </div>

                            {props.series == "series" ? <>
                                <div>Repeat

                                    <Select value={select} onChange={(e) => { console.log(e.value); setSelect(e) }}
                                        options={options}
                                    />



                                    <div>End Repeat

                                        <div> <label>Forever</label><Switch checked={forever} onClick={() => setForever(!forever)} /></div>
                                        {select != "never" ? !forever ?
                                            <div>
                                                <div style={{ display: "flex" }}>
                                                    <div>
                                                        <input
                                                            type="radio"
                                                            checked={!until}
                                                            onClick={() => setUntil(!until)}
                                                        />
                                                    </div>
                                                    <div>
                                                        After
                                                        <input disabled={until} value={after} onChange={(o) => { console.log(o.target.value); setAfter(o.target.value) }} type='number' />
                                                    </div>
                                                    <label>occurrences</label>
                                                </div>
                                                <div style={{ display: "flex" }}>
                                                    <div>
                                                        <input
                                                            type="radio"
                                                            checked={until}
                                                            onClick={() => setUntil(!until)}
                                                        />
                                                    </div>
                                                    <div>
                                                        Until
                                                        <DatePicker disabled={!until} value={endRepeat} onChange={setEndRepeat} />
                                                    </div>
                                                </div>
                                            </div>
                                            : null
                                            :
                                            <div></div>
                                        }
                                    </div>
                                </div>

                                {select.value == "custom" ?
                                    <>
                                        <div>Frequency

                                            <Select value={cSelect} options={custOptions} onChange={setCSelect} />

                                            {cSelect.value == "weekly" ?
                                                <Select
                                                    isMulti={true}
                                                    options={dayOptions}
                                                    value={cSelect2}
                                                    onChange={setCSelect2}
                                                    labelledBy="Select"
                                                />
                                                :
                                                cSelect.value == "monthly" ?
                                                    <div>
                                                        <Switch value={eachOr} onChange={() => setEachOr(!eachOr)} />
                                                        {eachOr ?
                                                            <>
                                                                <div>Each...</div>
                                                                <DatePicker multiple={true} value={multiDate} onChange={(dates) => { setMultiDate(dates) }} />
                                                            </>
                                                            :
                                                            <>
                                                                <div>on the...{monthly.label}</div>

                                                                <Select isMulti={false} options={onthe} onChange={setOnThe} value={onThe} />
                                                                <Select options={dayOptions} onChange={setMonthly} value={monthly} />



                                                            </>
                                                        }
                                                    </div>
                                                    :
                                                    cSelect.value == "yearly" ?
                                                        <div>Yearly</div>
                                                        :
                                                        <div></div>
                                            }
                                        </div>
                                        <div>Every <input value={every} onChange={(o) => { setEvery(o.target.value) }} type='number' /></div>
                                    </>
                                    : <div></div>}
                                {/* <div>
                                <label>Tags</label>
                                <Select
                                    isMulti={true}
                                    value={selectedTags}
                                    onChange={(e) => { setSelectedTags(e) }}
                                    options={props.tags}
                                />
                            </div> */}

                            </> : <></>}
                            {select.value == "never" ?
                                <button onClick={() => { CreateEvent(false, props.pos, props.event) }}>
                                    Add
                                </button>
                                :
                                <button onClick={() => { CreateEvent(true, props.pos, props.event) }}>
                                    Add
                                </button>
                            }










                            <button
                                onClick={() => props.setEdit(!props.edit)}
                                className='close-btn'>
                                Close
                            </button>


                        </div>
                    </div>
                )
            }
        </>
    )
}