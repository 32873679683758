import React,{useState,useEffect} from "react"
import {useApi} from '../config'
import { useAuth } from '../contexts/authContext/index.js';
import NavBar from "../components/NavBar"
import { useNavigate } from "react-router-dom"
import SideBar from "../components/SideBar"
export default function Milieus(){
    const { user, isAuthenticated, isLoading }  = useAuth()
    var nav = useNavigate();
    var api = useApi()
    const [milieus, setMilieus] = useState([])
    const [results,setResults] = useState([])

    useEffect(()=>{
        api.api.get(`/api/findAllMilieusByUser/?email=${user.email}`)
        .then((res) =>{
            setMilieus(res.data)
        })

    },[])
    useEffect(()=>{
        if(isAuthenticated){
            api.api.post('/api/signup',{email:user.email})
        }},[isLoading])
    if (isLoading) {
        return <div>Loading ...</div>;
    }

    return(
        <div>     
        <NavBar/> 
        <SideBar/>
            <h1>
                Milieus
            </h1>
            {milieus.map(i=>
            <div onClick={()=>{nav(`/milieu/${i.name}/${i.milieus_id}`)}}>
                {i.name}
            </div>)}
        </div>
    )
}