

import React,{useState} from 'react'

import { useAuth } from '../contexts/authContext/index.js';
import { Link } from 'react-router-dom';
import '../css/home.css'
import { CgCommunity } from "react-icons/cg";
import { HiMiniUserGroup } from "react-icons/hi2";
import { MdGroupAdd } from "react-icons/md";
import { FaUserFriends } from "react-icons/fa";
import { BiSolidMessageAltDetail } from "react-icons/bi";
import { IoCalendarNumber } from "react-icons/io5";
import { IoIosCreate } from "react-icons/io";
import { TbCalendarPlus } from "react-icons/tb";
import SideBarItem from './SideBarItem';
export default function SideBar({props}) {
    var user = useAuth();
    const [toggle,setToggle] = useState(false)
    return (
        <div onClick={()=>{setToggle(!toggle)}} className={toggle?'sideBar active':'sideBar'}>
            <div className="content">
                {SideBarItem({icon:HiMiniUserGroup(),link:"/milieus",name:"Milieus"})}
                {SideBarItem({icon:MdGroupAdd(),link:"/createMilieu",name:"Create Milieu"})}
                {SideBarItem({icon:FaUserFriends(),link:"/contacts",name:"Contacts"})}
                {SideBarItem({icon:BiSolidMessageAltDetail(),link:"/notifications",name:"Messages"})}
            </div>
        </div>
    )
}